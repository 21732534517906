import {useState, useEffect} from 'react';
import {AuthService} from 'src/service/auth/authService';
import {useService} from './serviceLocatorHook';
import {Service} from '../service/serviceLocator';

export const useAuth = () => {
    const [authService] = useService<AuthService>(Service.AuthService);
    const [isAuthenticated, setIsAuthenticated] = useState(authService?.isAuthenticated());
    useEffect(() => {
        const disposable = authService?.isSignedInStream.subscribe((v) => {
            setIsAuthenticated(v);
        });
        return () => disposable?.unsubscribe();
    }, [authService]);

    return [isAuthenticated];
};

export const useUser = () => {
    const [user, setUser] = useState(null);
    const [authService] = useService<AuthService>(Service.AuthService);
    const [isAuthenticated] = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            const fetchData = async () => {
                const result = await authService?.getAuthPayload();
                setUser(result.Response);
            };
            fetchData().catch(error => {
                // error handler here.
            });
        }
    }, [isAuthenticated, authService]);
    return [user];
};
