import {IHttpClient, IServerResponse} from 'src/core/http';

// consider to have own service or merge service as StaticDataService.
export interface IStaticDataDefinitionService {
	getDefinition(context: string, definitionId: string): Promise<any>;

	saveDefinition(context: string, definitionId: string, data: any): Promise<any>;

	importDefinition(context: string, definitionId: string, data: any): Promise<any>;

	exportDefinition(context: string, definitionId: string, hash: string, fileName: string): Promise<any>;

	publishDefinition(context: string, definitionId: string, hash: string, comment: string): Promise<any>;

	restoreDefinition(context: string, definitionId: string, hash: string): Promise<void>;
}

export class StaticDataDefinitionService implements IStaticDataDefinitionService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async getDefinition(context: string, definitionId: string): Promise<any> {
        const result = await this.httpClient.get<IServerResponse<any>>(`${this.apiUrl}/staticData/definitions/context/${context}/definition/${definitionId}`);
        return result.data;
    }

    public async saveDefinition(context: string, definitionId: string, data: any): Promise<any> {
        const result = await this.httpClient.put<IServerResponse<any>>(`${this.apiUrl}/staticData/definitions/context/${context}/definition/${definitionId}`, data);
        return result;
    }

    public async importDefinition(context: string, definitionId: string, data: any): Promise<any> {
        const result = await this.httpClient.put(`${this.apiUrl}/staticData/definitions/context/${context}/definition/${definitionId}/import`, {
            data,
        });
        return result.data;
    }

    public async exportDefinition(context: string, definitionId: string, hash: string, fileName: string): Promise<any> {
        await this.httpClient.download(`${this.apiUrl}/staticData/definitions/context/${context}/definition/${definitionId}/hash/${hash}/export`, fileName);
    }

    public async restoreDefinition(context: string, definitionId: string, hash: string): Promise<void> {
        await this.httpClient.put(`${this.apiUrl}/staticData/definitions/context/${context}/definition/${definitionId}/hash/${hash}/restore`, {});
    }

    public async publishDefinition(context: string, definitionId: string, hash: string, comment: string): Promise<any> {
        const result = await this.httpClient.put(`${this.apiUrl}/staticData/definitions/context/${context}/definition/${definitionId}/hash/${hash}/publish`, {
            comment
        });
        return result.data;
    }
}
