import {IAuthService} from 'src/service/auth/authService';
import {IHttpClient} from './httpClient';
import {IHttpResponse} from './types';

/// wrapping httpClient but it tries to refresh token before requesting to the server.
export class AuthorizedHttpClient implements IHttpClient {
    private regularHttpClient: IHttpClient;
    private authService: IAuthService;

    constructor(httpClient: IHttpClient, authService: IAuthService)
    {
        this.regularHttpClient = httpClient;
        this.authService = authService;
    }

    public addHeader(key: string, value: string | number) {
        this.regularHttpClient.addHeader(key, value);
    }

    public removeHeader(key: string) {
        this.regularHttpClient.removeHeader(key);
    }

    public async get<T>(url: string, params?: {} | undefined, options?: {} | undefined): Promise<IHttpResponse<T>> {
        await this.refreshToken();
        return this.regularHttpClient.get(url, params, options);
    }

    public async put<T>(url: string, data: {}, options?: {} | undefined): Promise<IHttpResponse<T>> {
        await this.refreshToken();
        return this.regularHttpClient.put(url, data, options);
    }

    public async post<T>(url: string, data: {}, options?: {} | undefined): Promise<IHttpResponse<T>> {
        await this.refreshToken();
        return this.regularHttpClient.post(url, data, options);
    }

    public async delete<T>(url: string, params?: {} | undefined, options?: {} | undefined): Promise<IHttpResponse<T>> {
        await this.refreshToken();
        return this.regularHttpClient.delete(url, params, options);
    }

    public async download(url: string, fileName: string, params?: {} | undefined, options?: {} | undefined): Promise<void> {
        await this.refreshToken();
        return this.regularHttpClient.download(url, fileName, params, options);
    }

    private async refreshToken()
    {
        if(this.authService.isAuthenticated() && this.authService.isExpired())
        {
            await this.authService.refresh();
        }
    }
}