import React, {useState} from 'react';
import {
    Button,
    capitalize,
    Card,
    CardContent,
    Checkbox,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import {Check, Downloading} from '@mui/icons-material';
import {StaticDataTransferService, IStaticDataTransferService} from 'src/service/staticData';
import {useService} from 'src/hook/serviceLocatorHook';
import StaticDataSchemaMap from 'src/temp/staticDataSchemaMap';
import {Service} from '../../service/serviceLocator';
import {confirmAlert} from 'react-confirm-alert';

export interface StaticDataTransferState {
	endpoints: any;
	endpointArray: Array<any>;
	selectedEndpoint: string;
	comparisons: any;
	checkboxes: Map<string, boolean>;
	publish: boolean;
	validate: boolean;
	showLoading: boolean;
}

export interface StaticDataTransferProps {
	env: string;
	endpoints: any;
}

export const StaticDataTransfer = (props: StaticDataTransferProps) => {
    const [staticDataTransferService] = useService<IStaticDataTransferService>(Service.StaticDataTransferService);
    const [endpointArray, setEndPointArray] = useState<string[]>(Object.keys(props.endpoints));
    const [selectedEndPoint, setSelectedEndPoint] = useState<string>(endpointArray[0]);
    const [comparisons, setComparisons] = useState<any>(null);
    const [checkboxes, setCheckBoxes] = useState<Map<string, boolean>>(new Map<string, boolean>());
    const [publish, setPublish] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [validate, setValidate] = useState<boolean>(false);
    const [push, setPush] = useState<boolean>(false);

    const toggleAllCheckboxes = () => {
        let newState = false;
        let newStateDetermined = false;
        const cb = new Map<string, boolean>(checkboxes);
        for (const key of cb.keys()) {
            if (!newStateDetermined) {
                newState = !checkboxes.get(key);
                newStateDetermined = true;
            }
            cb.set(key, newState);
        }
        setCheckBoxes(cb);

    };

    const handleCheckboxCheck = (event) => {
        const cb = new Map<string, boolean>(checkboxes);
        cb.set(event.target.id, !cb.get(event.target.id));
        setCheckBoxes(cb);
    };

    const handleTransfer = async () => {
        const params = {
            publish, validate, push,
            contexts: new Array<string>(),
            definitions: new Array<any>(),
        };

        const toExport = {};
        for (const contextAndDefinitionId of checkboxes.keys()) {
            if (!checkboxes.get(contextAndDefinitionId)) {
                continue;
            }

            const split = contextAndDefinitionId.split('_');

            if (split.length === 1) {
                params.contexts.push(contextAndDefinitionId);
            } else {
                const context = split[0];

                if (!toExport[context]) {
                    toExport[context] = {};
                }

                toExport[context][split[1]] = 1;
            }
        }

        for (const context in comparisons) {
            for (const comparison of comparisons[context]) {
                if (!toExport[context]?.[comparison.id]) {
                    continue;
                }

                if (comparison.local.live.hash == comparison.remote.live.hash) {
                    continue;
                }

                params.definitions.push({
                    context,
                    id: comparison.id,
                    hash: comparison.remote.live.hash
                });
            }
        }
        setShowLoading(true);

        await staticDataTransferService?.transferStaticData(selectedEndPoint, params);
        window.location.reload();
    };

    const compareEnvironments = async () => {
        const data = await staticDataTransferService?.compareStaticData(selectedEndPoint);
        setComparisons(data.Response);
    };

    const renderEnvironments = () => {
        const endpoints: any = [];

        for (const endpoint of endpointArray) {
            endpoints.push(<option key={endpoint} value={endpoint}>{endpoint}</option>);
        }

        return <div key={'Environments'}>
            <select onChange={(event) => setSelectedEndPoint(event.target.value)}>
                {endpoints}
            </select>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant="contained" onClick={compareEnvironments}>Compare</Button>
        </div>;
    };

    const renderComparison = () => {
        const comparisonElements: any = [];

        if (!comparisons) {
            return comparisonElements;
        }

        for (const context in comparisons) {
            if (context === StaticDataSchemaMap.SharedContext) {
                comparisonElements.push(<tr key={`comparison_context_${context}`}>
                    <TableCell colSpan={4} style={{'fontWeight': 'bold'}}>{context}</TableCell>
                </tr>);
            } else {
                if (!checkboxes.has(context)) {
                    checkboxes.set(context, false);
                }

                comparisonElements.push(<tr key={`comparison_context_${context}`}>
                    <TableCell colSpan={3} style={{'fontWeight': 'bold'}}>{context}</TableCell>
                    <TableCell><Checkbox id={context} onChange={handleCheckboxCheck} checked={checkboxes.get(context)} /></TableCell>
                </tr>);
            }

            for (const comparison of comparisons[context]) {
                let textColor = '#000000';

                if (comparison.local?.live?.hash == comparison.remote?.live?.hash) {
                    textColor = '#2AE630';
                } else {
                    textColor = '#E6BE2A';
                }

                const localDate = new Date(comparison.local?.live?.timestamp);
                const remoteDate = new Date(comparison.remote?.live?.timestamp);

                const checkboxKey = `${context}_${comparison.id}`;

                if (!checkboxes.has(checkboxKey)) {
                    checkboxes.set(checkboxKey, false);
                }

                comparisonElements.push(<tr key={`comparison_definition_${comparison.id}`}>
                    <TableCell>{comparison.id}</TableCell>
                    <TableCell>
                        <span style={{color: textColor}}>{comparison.local?.live?.hash}</span><br />
						({localDate.toDateString()} - {localDate.toLocaleTimeString()})<br />
                        <span style={{'fontStyle': 'italic'}}>{comparison.local?.live?.comment}</span>
                    </TableCell>
                    <TableCell>
                        <span style={{color: textColor}}>{comparison.remote?.live?.hash}</span><br />
						({remoteDate.toDateString()} - {remoteDate.toLocaleTimeString()})<br />
                        <span style={{'fontStyle': 'italic'}}>{comparison.remote?.live?.comment}</span>
                    </TableCell>
                    <TableCell><Checkbox id={checkboxKey} onChange={handleCheckboxCheck} checked={checkboxes.get(checkboxKey)} /></TableCell>
                </tr>);
            }
        }

        return comparisonElements;
    };

    const showConfirmTransfer = () => {
        confirmAlert({
            title: 'Static Data Transfer',
            message: 'Are you sure you want to transfer?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        handleTransfer();
                    }
                },
                {
                    label: 'No',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {}
                }
            ]
        });
    };

    const renderActionButtons = () => {
        if (!comparisons) {
            return [];
        }

        const loading: any = [];

        if (showLoading) {
            loading.push(<div key={'transfer_loading'}>
                <CircularProgress /> Loading...
            </div>);
        }

        const pushOption: any = [];

        if (props.env == 'local' && selectedEndPoint.toLowerCase() == 'dev') {
            pushOption.push(<div key={'transfer_push'}>
				Push? <Checkbox onChange={() => setPush(!push)} /> <span style={{'fontStyle': 'italic'}}>This will push your local data to dev, not pull dev data to your local. BE VERY CAREFUL.</span>
            </div>);
        }

        return <div key={'action_buttons'}>
            <br /><br />
            <Button variant="contained" onClick={toggleAllCheckboxes}>Toggle All Definitions</Button>
            <br />
			Ignore Validation? <Checkbox onChange={() => setValidate(!validate)} /> <span style={{'fontStyle': 'italic'}}></span>
            <br />
			Publish? <Checkbox onChange={() => setPublish(!publish)} /> <span style={{'fontStyle': 'italic'}}>If you don't select 'Publish', the data will be imported, but not pushed.</span>
            <br />
            {pushOption}
            <Button variant="contained" color={'secondary'} onClick={showConfirmTransfer}>Transfer</Button>
            {loading}
        </div>;
    };


    return (
        <Container maxWidth="xl">
            <Stack spacing={2}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h4">Environment Selection</Typography>
                        <Stack spacing={2}>
                            {renderEnvironments()}
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <h5>Legend</h5>
                        <span style={{fontStyle: 'italic', color: '#2AE630'}}>Our Data Matches Their Data</span><br/>
                        <span style={{fontStyle: 'italic', color: '#E6BE2A'}}>Our Data Doesn't Match Their Data</span>
                        <Typography variant="h4">Versions</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 700}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Definition</TableCell>
                                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Ours ({capitalize(props.env)})</TableCell>
                                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Theirs ({selectedEndPoint || '--'})</TableCell>
                                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Select</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderComparison()}</TableBody>
                            </Table>
                        </TableContainer>
                        {renderActionButtons()}
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    );
};
