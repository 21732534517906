import {Button, Chip, Container, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {useState} from 'react';
import {Segment} from '@mui/icons-material';

export const convertSegmentsToString = (segments: RewardSegments) =>
{
    return `RR:${segments.action}:${segments.type}:${segments.id}:${segments.quantity}`;
};
export interface RewardFormProps {
    rewardTypes: string[];
    onChange(rewardString: string): void;
}

export interface RewardChipProps {
    segments: RewardSegments;
    onRemoved(): void;
}

export const RewardChip = (props: RewardChipProps) => {
    const {segments, onRemoved} = props;

    return (
        <Chip
            label={convertSegmentsToString(segments)}
            onDelete={onRemoved}
            deleteIcon={<DeleteIcon />}
            variant="outlined"
        />
    );
};

interface RewardSegments {
    id: string;
    action: string;
    type: string;
    quantity: number;
}

export interface RewardFormProps {
    rewardTypes: string[];
    onRewardStringChange(rewardString: string);
}

export const RewardForm = (props) => {
    const {rewardTypes, onRewardStringChange} = props;

    const [id, setId] = useState<string>('');
    const [action, setAction] = useState<string>('G');
    const [type, setType] = useState<string>('Resource');
    const [quantity, setQuantity] = useState<number>(1);
    const [rewardSegments, setRewardSegments] = useState<RewardSegments[]>([]);

    const types: any = [];

    for (const rewardType of rewardTypes) {
        types.push(<MenuItem key={rewardType} value={rewardType}>{rewardType}</MenuItem>);
    }

    const addReward = () =>
    {
        const newRewards = [
            ...rewardSegments, {
                id,
                action,
                type,
                quantity,
            }
        ];
        setRewardSegments(newRewards);
        onRewardStringChange(getRewardString(newRewards));
    };

    const removeReward = (index: number) => {
        rewardSegments.splice(index, 1);
        const newRewards = [...rewardSegments];
        setRewardSegments(newRewards);
        onRewardStringChange(getRewardString(newRewards));
    };

    const getRewardString = (rewards: RewardSegments[]): string => {
        console.log(rewards,'rewards');
        return rewards.map(segments => convertSegmentsToString(segments)).join(',');
    };

    return (
        <>

            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel htmlFor="action">Action</InputLabel>
                <Select id="action" defaultValue={action} onChange={(e) => setAction(e.target.value)}>
                    <MenuItem key={'G'} value={'G'}>Grant</MenuItem>
                    <MenuItem key={'R'} value={'R'}>Revoke</MenuItem>
                </Select>
            </FormControl>

            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel htmlFor="type">Type</InputLabel>
                <Select id="type" onChange={(e) => setType(e.target.value)} defaultValue={type}>
                    {types}
                </Select>
            </FormControl>

            <FormControl sx={{m: 1, minWidth: 120}}>
                <TextField id="rewardId" type={'text'} defaultValue={id} onChange={(e) => setId(e.target.value)} label='Reward ID' />
            </FormControl>

            <FormControl sx={{m: 1, minWidth: 120}}>
                <TextField type={'number'} defaultValue={quantity} onChange={(e) => setQuantity(Number.parseInt(e.target.value))} label='Quantity' />
            </FormControl>
            <Button onClick={addReward}>Add</Button>

            <div>
                <Stack direction="row" spacing={2}>
                    {rewardSegments.map((segments, index) =>
                        <RewardChip key={index} segments={segments} onRemoved={() => {
                            removeReward(index);
                        }}/>
                    )}
                </Stack>
            </div>

        </>
    );
};