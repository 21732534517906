import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Link} from 'react-router-dom';
import {Copyright} from '@mui/icons-material';
import {capitalize} from '@mui/material';

interface IndexProps {
	env: string;
}

const Index = (props: IndexProps) => {
    document.title = `Sapling Admin (${capitalize(props.env)})`;

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    <h1>Static Data</h1>
                    <Link to={'/admin/contexts'} style={{textDecoration: 'none'}}>Static Data</Link>

                    <h1>Players</h1>
                    <Link to={'/admin/players'} style={{textDecoration: 'none'}}>Players</Link><br />
                    <Link to={'/admin/saveStates'} style={{textDecoration: 'none'}}>Save States</Link>

                    <h1>Static Data Transfer</h1>
                    <Link to={'/admin/staticDataTransfer'} style={{textDecoration: 'none'}}>Static Data Transfer</Link>

                    <h1>Maintenance</h1>
                    <Link to={'/admin/maintenance'} style={{textDecoration: 'none'}}>Maintenance</Link>
                </div>

                <Copyright />
            </Box>
        </Container>
    );
};

export default Index;
