export interface IHttpResponse<T> {
    hasError: boolean,
    data: T
}

export interface IServerResponse<T> {
    ErrorCode: null | number;
    Response: T;
    ResponseCode: 'Ok' | 'Error';
}

export type HttpHeader = { [key: string]: number | string };
export const AUTHORIZATION_HEADER = 'Authorization';

