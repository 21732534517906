import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {useService} from 'src/hook/serviceLocatorHook';
import {useEffect, useState} from 'react';
import {IPlayerService, PlayerService} from 'src/service/player/playerService';
import PlayerProfile from 'src/component/player/playerProfile';
import {useParams} from 'react-router-dom';
import {Service} from '../service/serviceLocator';
import {IInboxService} from 'src/service/inbox/inboxService';

interface PlayerProfilePageProps {
	env: string;
}

const PlayerProfilePage = (props: PlayerProfilePageProps) => {
    const params = useParams();
    const [playerService] = useService<IPlayerService>(Service.PlayerService);
    const [inboxService] = useService<IInboxService>(Service.InboxService);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [data, setData] = useState<any>();
    const [inbox, setInbox] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await playerService?.getPlayerProfile(params.id || '');
            const inboxResult = await inboxService?.getPlayerInboxItems(params.id || '');
            setData(result);
            setInbox(inboxResult);
            setIsFetching(false);
        };

        fetchData().catch((error) => {
            // issue was there.
        });
    }, [playerService, params]);
    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    {isFetching && <div>Loading data...</div>}
                    {!isFetching && data && (<PlayerProfile env={props.env} playerId={params.id || ''} contexts={data.contexts} schema={data.schema} gcpProjectId={data.gcpProjectId} player={data.player} telemetryEventIds={data.telemetryEventIds} inboxes={inbox.Response || {}} />)}
                </div>
                <div>

                </div>

                <Copyright />
            </Box>
        </Container>
    );
};

export default PlayerProfilePage;
