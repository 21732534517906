import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Container, Stack, Typography} from '@mui/material';
import StaticDataSchemaMap from 'src/temp/staticDataSchemaMap';
import {Context} from './context';

export interface ContextsProps {
	contexts: any;
}

export interface ContextsState {
	activeContext: string;
	contexts: Array<string>;
	newContext: string;
}

export default class Contexts extends React.Component<ContextsProps, ContextsState> {
    private readonly _schemas: StaticDataSchemaMap;
    private readonly _editingContent: Array<any>;
    private readonly _liveContent: Array<any>;

    private _contexts: Array<string>;

    constructor(props) {
        super(props);

        this._schemas = StaticDataSchemaMap.deserialize(props.contexts.schemas);
        this._editingContent = props.contexts.editingContent || {};
        this._liveContent = props.contexts.liveContent || {};

        this._contexts = [];

        for (const context in this._editingContent) {
            if (context === StaticDataSchemaMap.SharedContext) {
                continue;
            }

            this._contexts.push(context);
        }

        this.state = {
            activeContext: this._contexts.length > 0 ? this._contexts[0] : '',
            contexts: this._contexts,
            newContext: ''
        };
    }

    private handleNewContext = (event) => {
        const existingContexts = this.state.contexts;
        existingContexts.push(this.state.newContext);

        this._editingContent[this.state.newContext] = {manifests: {}};
        this._liveContent[this.state.newContext] = {manifests: {}};

        this.setState({
            activeContext: this.state.newContext,
            contexts: existingContexts,
            newContext: ''
        });

        event.preventDefault();
    };

    private handleNewContextIdChanged = (event) => {
        this.setState({
            activeContext: this.state.newContext,
            contexts: this.state.contexts,
            newContext: event.target.value
        });

        event.preventDefault();
    };

    private changeActiveContext = (event) => {
        this.setState({
            activeContext: event.target.value,
            contexts: this.state.contexts,
            newContext: event.target.value
        });

        event.preventDefault();
    };

    private renderActiveContext = () => {
        if (Object.keys(this._editingContent).length === 1) { // KK - We know there will always be one shared at least
            return;
        }

        return (<div id="active_context">
            <Typography variant="h4">{this.state.activeContext}</Typography>
            <Context key={`context_parent_${this.state.activeContext}`} schemas={this._schemas} editingContent={this._editingContent[this.state.activeContext]}
                liveContent={this._liveContent[this.state.activeContext]} editingSharedContent={this._editingContent['Shared']} liveSharedContent={this._liveContent['Shared']} />
        </div>);
    };

    render() {
        const ids: any = [];

        for (const id of this.state.contexts) {
            ids.push(<option key={id} value={id}>{id}</option>);
        }

        return (
            <div>
                <h1>Static Data</h1>
                <Container maxWidth="xl">
                    <Stack spacing={2}>
                        <Card variant="outlined">
                            <CardContent>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary><Typography variant="h5">Admin</Typography></AccordionSummary>
                                    <AccordionDetails>
                                        <form onSubmit={this.handleNewContext}>
                                            <label>ID: <input type="text" name="newId" defaultValue={this.state.newContext} onChange={this.handleNewContextIdChanged} /> </label>
                                            <Button variant={'contained'} type="submit">Create New Context</Button>
                                        </form>
                                    </AccordionDetails>
                                </Accordion>
                            </CardContent>
                        </Card>
                    </Stack>
                </Container>

                <Container maxWidth="xl">
                    <Stack spacing={2}>
                        <Card variant="outlined">
                            <CardContent>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary><Typography variant="h5">Switch Context</Typography></AccordionSummary>
                                    <AccordionDetails>
                                        <select value={this.state.activeContext} onChange={this.changeActiveContext}>
                                            {ids}
                                        </select>
                                    </AccordionDetails>
                                </Accordion>
                            </CardContent>
                        </Card>
                    </Stack>
                </Container>

                <Container maxWidth="xl">
                    <Stack spacing={2}>
                        <Card variant="outlined">
                            <CardContent>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary><Typography variant="h5">{this.state.activeContext}</Typography></AccordionSummary>
                                    <AccordionDetails>
                                        {this.renderActiveContext()}
                                    </AccordionDetails>
                                </Accordion>
                            </CardContent>
                        </Card>
                    </Stack>
                </Container>
            </div>
        );
    }
}
