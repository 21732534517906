import {IHttpClient, IServerResponse} from 'src/core/http';

// consider to have own service or merge service as StaticDataService.
export interface IStaticDataTransferService {
    compareStaticData(selectedEndpoint: string): Promise<any>;
    transferStaticData(selectedEndpoint: string, params: any): Promise<any>;
    getTransferTarget(): Promise<any>;
}

export class StaticDataTransferService implements IStaticDataTransferService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async compareStaticData(selectedEndpoint: string): Promise<any> {
        const result = await this.httpClient.get(`${this.apiUrl}/staticData/transfer/compare/${selectedEndpoint}`);
        return result.data;
    }

    public async transferStaticData(selectedEndpoint: string, params: any): Promise<any> {
        const result = await this.httpClient.post(`${this.apiUrl}/staticData/transfer/${selectedEndpoint}`, params);
        return result.data;
    }

    public async getTransferTarget(): Promise<any> {
        const result = await this.httpClient.get(`${this.apiUrl}/staticData/transfer/targets`);
        return result.data;
    }

}
