import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

export const localTheme = createTheme({
    palette: {
        secondary: {
            main: '#8A9A5B',
        },
        error: {
            main: red.A400,
        },
    },
});


export const devTheme = createTheme({
    palette: {
        secondary: {
            main: '#FFA500',
        },
        error: {
            main: red.A400,
        },
    },
});

export const playtestTheme = createTheme({
    palette: {
        secondary: {
            main: '#4CE8DA',
        },
        error: {
            main: red.A400,
        },
    },
});

export const internalTheme = createTheme({
    palette: {
        secondary: {
            main: '#C9A9EB',
        },
        error: {
            main: red.A400,
        },
    },
});

export const stageTheme = createTheme({
    palette: {
        secondary: {
            main: '#556cd6',
        },
        error: {
            main: red.A400,
        },
    },
});

export const prodTheme = createTheme({
    palette: {
        secondary: {
            main: '#00A300',
        },
        error: {
            main: red.A400,
        },
    },
});

