import React, {useState} from 'react';
import {Button, capitalize, Card, CardContent, Container, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {useService} from 'src/hook/serviceLocatorHook';
import {Service} from '../../../service/serviceLocator';
import {ISaveStateService} from '../../../service/player/saveStateService';
import {SaveState, SaveStateDialog} from './saveStateDialog';
import {confirmAlert} from 'react-confirm-alert';

export interface SaveStateProps {
    env: string;
    saveStates: Array<SaveState>;
}

export const SaveStates = (props: SaveStateProps) => {
    const [saveStateService] = useService<ISaveStateService>(Service.SaveStateService);
    const [openNewSaveStateForm, setOpenNewSaveStateForm] = useState<boolean>(false);
    const [editingSaveState, setEditingSaveState] = useState<SaveState>({id: '', name: '', description: '', saveData: ''});
    const [openEditSaveStateForm, setOpenEditSaveStateForm] = useState<boolean>(false);
    const [saveStateError, setSaveStateError] = useState<string>('');
    const [saveStates, setSaveStates] = useState<Array<SaveState>>(props.saveStates);

    const asyncHandleCreateNewSaveState = async (saveState: SaveState) => {
        const result = await saveStateService?.createSaveState(saveState);
        setOpenNewSaveStateForm(false);

        const newSaveStates: Array<SaveState> = Array.from(saveStates);
        newSaveStates.push(saveState);
        setSaveStates(newSaveStates);
    };

    const asyncHandleUpdateSaveState = async (updatedSaveState: SaveState) => {
        const result = await saveStateService?.updateSaveState(updatedSaveState.id, updatedSaveState);
        setOpenEditSaveStateForm(false);

        const newSaveStates: Array<SaveState> = Array.from(saveStates);

        for (let i = 0; i < newSaveStates.length; i++) {
            if (newSaveStates[i].id == updatedSaveState.id) {
                newSaveStates[i] = updatedSaveState;
            }
        }

        setSaveStates(newSaveStates);
    };

    const showConfirmDeleteSaveState = (saveStateId, saveStateName) => {
        confirmAlert({
            title: 'Delete Save State',
            message: `Are you sure you want to delete state ${saveStateName}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await saveStateService?.deleteSaveState(saveStateId);

                        const newSaveStates: Array<SaveState> = [];

                        for (const saveState of saveStates) {
                            if (saveState.id != saveStateId) {
                                newSaveStates.push(saveState);
                            }
                        }

                        setSaveStates(newSaveStates);
                    }
                },
                {
                    label: 'No',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {}
                }
            ]
        });
    };

    const showConfirmReplaceAllAccounts = (saveStateId, saveStateName) => {
        confirmAlert({
            title: 'Replace All Accounts',
            message: `Are you sure you want to replace all accounts on this environment with state ${saveStateName}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const result = await saveStateService?.replaceAllPlayersWithSaveState(saveStateId);

                        alert(`Replaced ${result?.Response} player accounts.`);
                    }
                },
                {
                    label: 'No',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {}
                }
            ]
        });
    };

    const importSaveStates = (event) => {
        const reader = new FileReader();

        let fileLoaded = (e) => {
            handleImportSaveStates(e.target.result);
        };

        fileLoaded = fileLoaded.bind(this);
        reader.onload = fileLoaded;
        reader.readAsText(event.target.files[0]); // read the file
    };

    const handleImportSaveStates = async (data: any) => {
        await saveStateService?.importSaveStates(data);
        window.location.reload();
    };

    const renderSaveStates = () => {
        const saveStateRender: Array<any> = [];

        for (const saveState of saveStates) {
            const replaceAllAccountsContent: any[] = [];

            if (props.env.toLowerCase() !== 'prod') {
                replaceAllAccountsContent.push(<Button variant={'contained'} color={'warning'} onClick={() => {
                    showConfirmReplaceAllAccounts(saveState.id, saveState.name);
                }}>Replace All Accounts</Button>);
            }

            saveStateRender.push(<TableRow key={saveState.id}>
                <TableCell>{saveState.name}</TableCell>
                <TableCell>{saveState.description}</TableCell>
                <TableCell>
                    <Button variant="contained" onClick={() => {
                        setEditingSaveState(saveState);
                        setOpenEditSaveStateForm(true);
                    }}>Edit</Button>

                    {replaceAllAccountsContent}

                    <Button variant={'contained'} color={'error'} onClick={() => {
                        showConfirmDeleteSaveState(saveState.id, saveState.name);
                    }}>Delete</Button>
                </TableCell>
            </TableRow>);
        }

        return saveStateRender;
    };

    return (
        <Container maxWidth="xl">
            <Stack spacing={2}>
                <Card variant="outlined">
                    <CardContent>
                        <Typography variant="h4">Admin</Typography>
                        <Stack spacing={2}>
                            <SaveStateDialog handleClose={() => setOpenNewSaveStateForm(false)} handleSubmit={asyncHandleCreateNewSaveState} open={openNewSaveStateForm} error={saveStateError} existingState={{id: '', name: '', description: '', saveData: ''}} />
                            <SaveStateDialog handleClose={() => setOpenEditSaveStateForm(false)} handleSubmit={asyncHandleUpdateSaveState} open={openEditSaveStateForm} error={saveStateError} existingState={editingSaveState} />

                            <h2>General</h2>
                            <Button variant="contained" onClick={() => {
                                setOpenNewSaveStateForm(true);
                            }}>Create Save State</Button>

                            <h2>Import/Export</h2>
                            Import <input type="file" multiple={false} accept=".json,application/json" onChange={importSaveStates} />
                            <Button variant="contained" color={'info'} onClick={() => {
                                const toExport: Array<string> = [];

                                for (const saveState of saveStates) {
                                    toExport.push(saveState.id);
                                }

                                saveStateService?.exportSaveStates(toExport, `${props.env}-saveStates-export.json`);
                            }}>Export Save States</Button>
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 700}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Name</TableCell>
                                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Description</TableCell>
                                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{renderSaveStates()}</TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    );
};
