import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {useService} from 'src/hook/serviceLocatorHook';
import {useEffect, useState} from 'react';
import {Service} from '../service/serviceLocator';
import {IMaintenanceService, Maintenance, MaintenanceStatus} from '../service/maintenance/maintenanceService';
import {Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, CircularProgress, FormControl, Grid, Paper, Stack, TextField, Typography} from '@mui/material';
import {MaintenanceDialog} from '../component/maintenance/maintenanceDialog';
import {InboxDialog, InboxItem} from '../component/player/inbox/inboxDialog';
import {IStaticDataContextService} from '../service/staticData';
import {IInboxService} from '../service/inbox/inboxService';
import {confirmAlert} from 'react-confirm-alert';
import {IPlayerService} from '../service/player/playerService';

interface MaintenancePageProps {
    env: string;
}

const defaultMaintenanceState = {
    AllowedUIDs: [],
    OverrideMessage: '',
    Status: 0,
    PlayerBannerMessage: '',
};
const MaintenancePage = (props: MaintenancePageProps) => {
    const [maintenanceService] = useService<IMaintenanceService>(Service.MaintenanceService);
    const [staticDataContextService] = useService<IStaticDataContextService>(Service.StaticDataContextService);
    const [inboxService] = useService<IInboxService>(Service.InboxService);
    const [playerService] = useService<IPlayerService>(Service.PlayerService);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [maintenanceData, setMaintenanceData] = useState<Maintenance>(defaultMaintenanceState);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openInboxForm, setOpenInboxForm] = useState<boolean>(false);
    const [schema, setSchema] = useState<any>();
    const [grantRevokeError, setGrantRevokeError] = useState<string>('');
    const [playerBannerMessage, setPlayerBannerMessage] = useState<string>('');
    const [playerBannerMessageResult, setPlayerBannerMessageResult] = useState<string>('');
    const [playersDeletedResult, setPlayersDeletedResult] = useState<boolean>(false);

    const handleSendPlayerBannerMessage = async () => {
        const result = await maintenanceService?.setPlayerBannerMessage(playerBannerMessage);
        if (result?.ErrorCode === null) {
            setPlayerBannerMessageResult('Sent.');

            setMaintenanceData({
                ...maintenanceData,
                PlayerBannerMessage: playerBannerMessage
            });
        } else {
            setPlayerBannerMessageResult('Internal server error');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await maintenanceService?.getMaintenanceState();
            const schemeResult = await staticDataContextService?.getStaticDataSchema();
            setSchema(schemeResult.schema.RewardType.properties.map((t) => t));
            setMaintenanceData(result?.Response || defaultMaintenanceState);
            setIsFetching(false);
        };

        fetchData().catch((error) => {
            // issue was there.
        });
    }, [maintenanceService]);

    const asyncHandleSubmitGrantRevoke = async (inboxItem: InboxItem) => {
        const result = await inboxService?.sendInboxToAll(inboxItem);
    };

    const showConfirmDeleteAllPlayers = () => {
        confirmAlert({
            title: 'Delete All Players',
            message: 'Are you sure you want to delete all players?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await playerService?.deleteAllPlayers();
                        setPlayersDeletedResult(true);
                    }
                },
                {
                    label: 'No',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {
                    }
                }
            ]
        });
    };

    const renderDangerZone = () => {
        if (props.env.toLowerCase() == 'prod') {
            return;
        }

        return <div>
            <Button variant="contained" color={'error'} onClick={() => {
                showConfirmDeleteAllPlayers();
            }}>Delete All Players</Button>
            <Typography>{playersDeletedResult}</Typography>
        </div>;
    };

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {isFetching ?
                    <>
                        <CircularProgress/> Loading...
                    </>
                    :
                    <>
                        <MaintenanceDialog
                            open={openDialog}
                            currentMessage={maintenanceData?.OverrideMessage || ''}
                            currentStatus={maintenanceData?.Status || 0}
                            handleClose={() => {
                                setOpenDialog(false);
                            }}
                            handleSubmit={async (message, status) => {
                                await maintenanceService?.setMaintenanceState(message, status);
                            }}
                        />
                        <InboxDialog rewardTypes={schema} handleClose={() => setOpenInboxForm(false)} handleSubmit={asyncHandleSubmitGrantRevoke} open={openInboxForm} error={grantRevokeError} env={props.env} />

                        <Container maxWidth="xl">
                            <Stack spacing={2}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary><Typography variant="h5">Maintenance</Typography></AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container>
                                                    <Grid xs={5}>
                                                        <Typography>
                                                            Current State: {MaintenanceStatus[maintenanceData?.Status || 0]}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={5}>
                                                        <Typography>
                                                            Maintenance Message: {maintenanceData?.OverrideMessage}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={2}>
                                                        <Button variant={'contained'} onClick={() => {
                                                            setOpenDialog(true);
                                                        }}>Update</Button>
                                                    </Grid>
                                                </Grid>
                                                <Typography>
                                                    Allowed UIDs:
                                                </Typography>
                                                {maintenanceData?.AllowedUIDs.map(uid => <Typography key={uid}>{uid}</Typography>)}
                                            </AccordionDetails>
                                        </Accordion>
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Container>
                        <Container maxWidth="xl">
                            <Stack spacing={2}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary><Typography variant="h5">Player Banner</Typography></AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    Current Player Banner Message: {maintenanceData?.PlayerBannerMessage}
                                                </Typography>

                                                <FormControl sx={{m: 1, minWidth: 500}}>
                                                    <TextField id="message" type={'text'} onChange={(event) => {
                                                        setPlayerBannerMessage(event.target.value);
                                                    }} label="Message"/>
                                                </FormControl>
                                                <FormControl sx={{m: 1, minWidth: 100}}>
                                                    <Button variant="contained" onClick={handleSendPlayerBannerMessage}>Send Message to Players</Button>
                                                </FormControl>
                                                <Typography>{playerBannerMessageResult}</Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Container>
                        <Container maxWidth="xl">
                            <Stack spacing={2}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary><Typography variant="h5">Mass Inbox Grant</Typography></AccordionSummary>
                                            <AccordionDetails>
                                                <Button variant="contained" onClick={() => {
                                                    setOpenInboxForm(true);
                                                }}>Send Inbox Item to All Players</Button>
                                            </AccordionDetails>
                                        </Accordion>
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Container>
                        <Container maxWidth="xl">
                            <Stack spacing={2}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Accordion defaultExpanded={false}>
                                            <AccordionSummary><Typography variant="h5">Danger Zone</Typography></AccordionSummary>
                                            <AccordionDetails>
                                                {renderDangerZone()}
                                            </AccordionDetails>
                                        </Accordion>
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Container>
                    </>
                }

                <Copyright/>
            </Box>
        </Container>
    );
};

export default MaintenancePage;
