import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {useService} from 'src/hook/serviceLocatorHook';
import {useEffect, useState} from 'react';
import {Service} from '../service/serviceLocator';
import {ISaveStateService} from '../service/player/saveStateService';
import {SaveStates} from '../component/player/saveState/saveStates';

interface SaveStatesPageProps {
    env: string;
}

const SaveStatesPage = (props: SaveStatesPageProps) => {

    const [saveStateService] = useService<ISaveStateService>(Service.SaveStateService);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [saveStates, setSaveStates] = useState<any>();
    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await saveStateService?.getSaveStates();
            setSaveStates(result.Response || []);
            setIsFetching(false);
        };

        fetchData().catch((error) => {
            // issue was there.
        });
    }, [saveStateService]);

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    <h1>Save States</h1>
                    {!isFetching && saveStates && (<SaveStates env={props.env} saveStates={saveStates}/>)}
                </div>
                <Copyright/>
            </Box>
        </Container>
    );
};

export default SaveStatesPage;
