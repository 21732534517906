import {IHttpClient, IServerResponse} from 'src/core/http';

// consider to have own service or merge service as StaticDataService.
export interface IStaticDataSetService {
	createSet(context: string, definitionId: string, data: any): Promise<any>;

	getSet(context: string, definitionId: string, setId: string): Promise<any>;

	updateSet(context: string, definitionId: string, setId: string, data: any): Promise<any>;

	updateSetConditions(context: string, definitionId: string, setId: string, data: any): Promise<any>;

	updateSetPriority(context: string, definitionId: string, setId: string, priority: number, data: any): Promise<any>;

	deleteSet(context: string, definitionId: string, setId: string): Promise<any>;

	importSet(context: string, definitionId: string, setId: string, data: any): Promise<any>;

	exportSet(context: string, definitionId: string, setId: string, fileName: string): Promise<any>;

    importFromSheet(context: string, definitionId: string, setId: string, sheetId: string, sheetTabName: string): Promise<any>;

    exportSetIntoSheet(context: string, definitionId: string, setId: string, sheetId: string, sheetTableName: string, clearExisting: boolean): Promise<any>;
}

export class StaticDataSetService implements IStaticDataSetService {
    private readonly apiUrl: string;
    private readonly httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async createSet(context: string, definitionId: string, data: any): Promise<any> {
        const result = await this.httpClient.post<IServerResponse<any>>(`${this.apiUrl}/staticData/sets/context/${context}/definition/${definitionId}`, data);
        return result.data;
    }

    public async getSet(context: string, definitionId: string, setId: string): Promise<any> {
        const result = await this.httpClient.get<IServerResponse<any>>(`${this.apiUrl}/staticData/sets/context/${context}/definition/${definitionId}/set/${setId}`);
        return result.data;
    }

    public async updateSet(context: string, definitionId: string, setId: string, data: any): Promise<any> {
        console.log(data);
        const result = await this.httpClient.put<IServerResponse<any>>(`${this.apiUrl}/staticData/sets/context/${context}/definition/${definitionId}/set/${setId}`, data);
        return result.data;
    }

    public async updateSetConditions(context: string, definitionId: string, setId: string, data: any): Promise<any> {
        const result = await this.httpClient.put<IServerResponse<any>>(`${this.apiUrl}/staticData/sets/context/${context}/definition/${definitionId}/set/${setId}/conditions`, data);
        return result.data;
    }

    public async updateSetPriority(context: string, definitionId: string, setId: string, priority: number, data: any): Promise<any> {
        const result = await this.httpClient.put<IServerResponse<any>>(`${this.apiUrl}/staticData/sets/context/${context}/definition/${definitionId}/set/${setId}/priority/${priority}`, data);
        return result.data;
    }

    public async deleteSet(context: string, definitionId: string, setId: string): Promise<any> {
        const result = await this.httpClient.delete<IServerResponse<any>>(`${this.apiUrl}/staticData/sets/context/${context}/definition/${definitionId}/set/${setId}`);
        return result.data;
    }

    public async importSet(context: string, definitionId: string, setId: string, data: any): Promise<any> {
        const result = await this.httpClient.put<IServerResponse<any>>(`${this.apiUrl}/staticData/sets/context/${context}/definition/${definitionId}/set/${setId}/import`, data);
        return result.data;
    }

    public async exportSet(context: string, definitionId: string, setId: string, fileName: string): Promise<any> {
        await this.httpClient.download(`${this.apiUrl}/staticData/sets/context/${context}/definition/${definitionId}/set/${setId}/export`, fileName);
    }

    public async importFromSheet(context: string, definitionId: string, setId: string, sheetId: string, sheetTabName: string): Promise<any> {
        await this.httpClient.put<IServerResponse<any>>(`${this.apiUrl}/staticData/sets/context/${context}/definition/${definitionId}/set/${setId}/sheet/${sheetId}/tab/${sheetTabName}/import`, {});
    }

    public async exportSetIntoSheet(context: string, definitionId: string, setId: string, sheetId: string, sheetTabName: string, clearExisting: boolean): Promise<any> {
        await this.httpClient.get(`${this.apiUrl}/staticData/sets/context/${context}/definition/${definitionId}/set/${setId}/sheet/${sheetId}/tab/${sheetTabName}/export/${clearExisting}`);
    }
}
