import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {useState} from 'react';
import {RewardForm} from './rewardForm';

export enum InboxItemVisibility {
    None,
    Inbox,
    Interstitial
}

export enum InboxActionType {
    None,
    DeepLink,
    ExternalLink,
}

export interface InboxItem {
    author?: string;
    context?: string;
    imageUrl?: string;
    title?: string;
    message?: string;
    comment?: string;
    ticketRef?: string;
    visibility: InboxItemVisibility;
    actionType: InboxActionType;
    action?: string;
    hasReward: boolean;
    rewardString?: string;
    //resource related
}

export interface IInboxDialogProps {
    rewardTypes: string[];
    error?: string;
    open: boolean;
    handleClose: () => void;
    handleSubmit: (inboxItem: InboxItem) => Promise<void>;
    env: string;
}

export const InboxDialog = (props: IInboxDialogProps) => {
    const {rewardTypes, open, handleClose, handleSubmit, error} = props;
    const [commentError, setCommentError] = useState<string>();
    const [ticketRefError, setTicketRefError] = useState<string>();
    const [inboxItem, setInboxItem] = useState<InboxItem>({
        visibility: InboxItemVisibility.None,
        title: 'Admin Grant',
        context: 'Core',
        hasReward: false,
        imageUrl: '',
        rewardString: '',
        actionType: InboxActionType.None,
        action: ''
    });


    const handleSubmitInternal = () => {
        if (!inboxItem.comment) {
            setCommentError('Comment required.');
            return;
        }

        if (props.env === 'prod' && Number.isNaN(parseInt(inboxItem.ticketRef || ''))) {
            setTicketRefError('Ticket reference required.');
            return;
        }

        setCommentError('');
        setTicketRefError('');

        handleSubmit(inboxItem);
    };

    const changeInboxTitle = (event) => {
        const newInboxItem = {
            ...inboxItem,
            title: event.target.value
        };
        setInboxItem(newInboxItem);
    };

    const changeInboxImageUrl = (event) => {
        const newInboxItem = {
            ...inboxItem,
            imageUrl: event.target.value
        };
        setInboxItem(newInboxItem);
    };

    const changeInboxMessage = (event) => {
        const newInboxItem = {
            ...inboxItem,
            message: event.target.value
        };
        setInboxItem(newInboxItem);
    };

    const changeInboxComment = (event) => {
        const newInboxItem = {
            ...inboxItem,
            comment: event.target.value
        };
        setInboxItem(newInboxItem);
    };

    const changeInboxTicketRef = (event) => {
        const newInboxItem = {
            ...inboxItem,
            ticketRef: event.target.value
        };
        setInboxItem(newInboxItem);
    };

    const handleRewardStringChange = (rewardString: string) => {
        const newInboxItem = {
            ...inboxItem,
            rewardString: rewardString
        };
        setInboxItem(newInboxItem);
    };

    const changeInboxVisibility = (event) => {
        const newInboxItem = {
            ...inboxItem,
            visibility: event.target.value
        };
        setInboxItem(newInboxItem);
    };

    const changeInboxRewardContext = (event) => {
        const newInboxItem = {
            ...inboxItem,
            context: event.target.value
        };
        setInboxItem(newInboxItem);
    };

    const changeInboxAcitonType = (event) => {
        const newInboxItem = {
            ...inboxItem,
            actionType: event.target.value
        };
        setInboxItem(newInboxItem);
    };

    const changeInboxAciton = (event) => {
        const newInboxItem = {
            ...inboxItem,
            action: event.target.value
        };
        setInboxItem(newInboxItem);
    };

    const toggleReward = (event) => {
        const newInboxItem = {
            ...inboxItem,
            hasReward: !inboxItem.hasReward,
        };
        setInboxItem(newInboxItem);
    };


    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>New Inbox Item</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <FormControl sx={{m: 1, minWidth: 120}}>
                        <InputLabel htmlFor="visibility">Visibility</InputLabel>
                        <Select defaultValue={1} id="visibility" onChange={changeInboxVisibility}>
                            <MenuItem key={'Inbox'} value={InboxItemVisibility.None}>None</MenuItem>
                            <MenuItem key={'Inbox'} value={InboxItemVisibility.Inbox}>Inbox</MenuItem>
                            <MenuItem key={'Inbox'} value={InboxItemVisibility.Interstitial}>Interstitial</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 120}}>
                        <TextField id="title" type={'text'} defaultValue={inboxItem.title} onChange={changeInboxTitle} label="Title"/>
                    </FormControl>
                    {inboxItem.visibility === InboxItemVisibility.Interstitial &&
                        <div>
                            <FormControl sx={{m: 1, minWidth: 120}}>
                                <InputLabel htmlFor="actionType">Action Type</InputLabel>
                                <Select id="actionType" defaultValue={inboxItem.actionType} onChange={changeInboxAcitonType}>
                                    <MenuItem key={'None'} value={InboxActionType.None}>None</MenuItem>
                                    <MenuItem key={'DeepLink'} value={InboxActionType.DeepLink}>DeepLink</MenuItem>
                                    <MenuItem key={'ExternalLink'} value={InboxActionType.ExternalLink}>External Link</MenuItem>
                                </Select>
                            </FormControl>
                            {inboxItem.actionType !== InboxActionType.None &&
                            <FormControl sx={{m: 1, minWidth: 120}}>
                                <TextField id="action" type={'text'} defaultValue={inboxItem.action} onChange={changeInboxAciton} label="Action"/>
                            </FormControl>
                            }
                            <FormControl sx={{m: 1, minWidth: 120}}>
                                <TextField id="title" type={'text'} defaultValue={''} onChange={changeInboxImageUrl} label="ImageUrl"/>
                            </FormControl>
                            <FormControl sx={{m: 1, minWidth: 330}}>
                                <TextField id="message" type={'text'} defaultValue={inboxItem.message} onChange={changeInboxMessage} multiline label="Message" rows={4}/>
                            </FormControl>
                        </div>
                    }
                    <FormControl sx={{m: 1, minWidth: 330}}>
                        <TextField id="comment" type={'text'} defaultValue={inboxItem.comment} onChange={changeInboxComment} multiline label="Comment" rows={4}/>
                        <span style={{'color': '#FF0000'}}>{commentError}</span>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 330}}>
                        <TextField id="ticketRef" type={'text'} defaultValue={inboxItem.ticketRef} onChange={changeInboxTicketRef} multiline label="Ticket Reference Id" rows={1}/>
                    </FormControl>
                    <FormControlLabel control={<Checkbox defaultChecked={inboxItem.hasReward} onClick={toggleReward}/>} label="Grant & Revoke Reward"/>
                    <span style={{'color': '#FF0000'}}>{ticketRefError}</span>
                </Box>
                {inboxItem.hasReward && <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <FormControl sx={{m: 1, minWidth: 120}}>
                        <InputLabel htmlFor="rewardContext">Reward Context</InputLabel>
                        <Select id="rewardContext" defaultValue={inboxItem.context} onChange={changeInboxRewardContext}>
                            <MenuItem key={'Core'} value={'Core'}>Core</MenuItem>
                        </Select>
                    </FormControl>
                    <RewardForm rewardTypes={rewardTypes} onRewardStringChange={handleRewardStringChange}/>
                </Box>}
                <span style={{color: error !== 'Success' ? '#FF0000' : '#00FF00'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmitInternal}>Send</Button>
            </DialogActions>
        </Dialog>
    );
};
