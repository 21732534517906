import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {Players} from 'src/component/player/players';


interface PlayerPageProps {
	env: string;
}

const Page = (props: PlayerPageProps) => {
    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    <Players />
                </div>
                <div>

                </div>

                <Copyright />
            </Box>
        </Container>
    );
};

export default Page;
