import {Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, styled, useTheme} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Link} from 'react-router-dom';

export interface ILink {
  title: string;
  link: string;
}

export interface INavigationDrawerProps {
  isOpened: boolean;
  setOpen(value: boolean): void;
  links?: ILink[];
}

const drawerWidth = 240;

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export const NavigationDrawer = (props: INavigationDrawerProps) => {
    const {links} = props;
    const theme = useTheme();
    const handleDrawerClose = () => {
        props.setOpen(false);
    };

    return (<Drawer
        sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
            },
        }}
        variant="persistent"
        anchor="left"
        open={props.isOpened}
    >
        <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
            {(links || []).map((link, index) => (
                <Link to={link.link} key={link.title} style={{textDecoration: 'none'}}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemText primary={link.title} />
                        </ListItemButton>
                    </ListItem>
                </Link>
            ))}
        </List>
        <Divider />
    </Drawer>);
};