import {BehaviorSubject} from 'rxjs';
import {IHttpClient, AUTHORIZATION_HEADER, IServerResponse} from 'src/core/http';


// https://cctech.atlassian.net/wiki/spaces/PROTOTYPE/pages/599130201/Maintenance+Runbook
export enum MaintenanceStatus {
    None,
    SoftMaintenance, // We won’t kick out players who are in-game, but no new players can join the game.
    HardMaintenance // Players are forcibly removed from the game the next time they make a server call.
}

export interface Maintenance {
    AllowedUIDs: string[];
    OverrideMessage: string;
    Status: MaintenanceStatus;
    PlayerBannerMessage: string;
}

export interface IMaintenanceService {
    getMaintenanceState(): Promise<IServerResponse<Maintenance>>;
    setMaintenanceState(maintenanceMessage: string, status: MaintenanceStatus);
    addAllowedUID(id: string);
    setPlayerBannerMessage(message: string):Promise<IServerResponse<{}>>;
}

export class MaintenanceService implements IMaintenanceService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    public isSignedInStream: BehaviorSubject<boolean>;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
        this.isSignedInStream = new BehaviorSubject(false);
    }
    public async getMaintenanceState(): Promise<IServerResponse<Maintenance>> {
        const result = await this.httpClient.get<IServerResponse<Maintenance>>(`${this.apiUrl}/maintenance`);
        return result.data;
    }

    public async setMaintenanceState(maintenanceMessage: string, status: MaintenanceStatus) {
        const result = await this.httpClient.post(`${this.apiUrl}/maintenance`, {
            message: maintenanceMessage,
            status: status
        });
        return result;
    }

    public async addAllowedUID(id: string) {
        const result = await this.httpClient.post(`${this.apiUrl}/maintenance/allow/${id}`, {});
        return result;
    }

    public async setPlayerBannerMessage(message: string) {
        const result = await this.httpClient.post<IServerResponse<{}>>(`${this.apiUrl}/maintenance/message`, {
            message,
        });
        return result.data;
    }

}