import React, {useState} from 'react';
import {Button} from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PlayersProps {
}


export const Players = (props: PlayersProps) => {
    const [playerId, setPlayerId] = useState<string>();


    const handleChangeFindPlayer = (event) => {
        setPlayerId(event.target.value);
        event.preventDefault();
    };

    const openInNewTab = () => {
        window.open(`players/${playerId}`, '_blank', 'noopener,noreferrer');
    };

    return (
        <div key={'FindPlayer'}>
            <h1>Find Player</h1>
            <form onSubmit={openInNewTab}>
                <label>ID: <input type="text" name="playerId" onChange={handleChangeFindPlayer} /> </label>
                <Button variant="contained" type="submit">Find Player</Button>
            </form>
        </div>
    );
};
