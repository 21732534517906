import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Copyright} from '@mui/icons-material';
import {StaticDataTransfer} from 'src/component/staticData/staticDataTransfer';
import {StaticDataTransferService, IStaticDataTransferService} from 'src/service/staticData';
import {useService} from 'src/hook/serviceLocatorHook';
import {useEffect, useState} from 'react';
import {Service} from '../service/serviceLocator';

interface StaticDataTransferPageProps {
	env: string;
}

const Page = (props: StaticDataTransferPageProps) => {

    const [staticDataTransferService] = useService<IStaticDataTransferService>(Service.StaticDataTransferService);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [targetEnvs, setTargetEnvs] = useState<any>();
    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await staticDataTransferService?.getTransferTarget();
            setTargetEnvs(result.Response);
            setIsFetching(false);
        };

        fetchData().catch((error) => {
            // issue was there.
        });
    }, [staticDataTransferService]);

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div>
                    <h1>Static Data Transfer</h1>
                    {!isFetching && targetEnvs && (<StaticDataTransfer env={props.env} endpoints={targetEnvs} />)}
                </div>
                <Copyright />
            </Box>
        </Container>
    );
};

export default Page;
