import {createBrowserRouter, RouteObject} from 'react-router-dom';

export interface IAppRouter {
  registerRoutes(routes: RouteObject[]);
}

export class AppRouter implements IAppRouter {

    public registerRoutes(routes: RouteObject[]) {
        return createBrowserRouter(routes);
    }
}
