import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextareaAutosize, TextField,} from '@mui/material';
import {useEffect, useState} from 'react';

export interface SaveState {
    id: string;
    name: string;
    description: string;
    saveData: any;
}

export interface SaveStateDialogProps {
    error?: string;
    open: boolean;
    existingState: SaveState;
    handleClose: () => void;
    handleSubmit: (saveState: SaveState) => Promise<void>;
}

export const SaveStateDialog = (props: SaveStateDialogProps) => {
    const {open, handleClose, handleSubmit, error} = props;

    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [saveData, setSaveData] = useState<string>('');

    useEffect(() => {
        setName(name || props.existingState.name);
        setDescription(description || props.existingState.description);
        setSaveData(saveData || props.existingState.saveData);
    }, []);

    const handleSubmitInternal = () => {
        handleSubmit({
            id: props.existingState.id,
            name: name || props.existingState.name,
            description: description || props.existingState.description,
            saveData: saveData || props.existingState.saveData
        });
    };

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Save State Editor</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <FormControl sx={{m: 1, minWidth: 120}}>
                        <TextField id="name" type={'text'} defaultValue={props.existingState.name} onChange={(evt) => setName(evt.target.value)} label='Name' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 120}}>
                        <TextField id="description" type={'text'} defaultValue={props.existingState.description} onChange={(evt) => setDescription(evt.target.value)} label='Description' />
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 475}}>
                        Data: <TextareaAutosize id="saveData" defaultValue={props.existingState.saveData} onChange={(evt) => setSaveData(evt.target.value)} minRows={25} />
                    </FormControl>
                </Box>
                <span style={{color: error !== 'Success' ? '#FF0000' : '#00FF00'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmitInternal}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};
