import {IHttpClient} from 'src/core/http';
import {InboxItem} from '../../component/player/inbox/inboxDialog';

export interface IInboxService {
    getPlayerInboxItems(playerId: string): Promise<any>;
    sendInboxToPlayer(playerId: string, reward: any): Promise<any>;
    sendInboxToPlayers(playerIds: string[], reward: any): Promise<any>;
    sendInboxToAll(reward: any): Promise<any>;
}

export class InboxService implements IInboxService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }
    public async sendInboxToAll(reward: any): Promise<any> {
        const result = await this.httpClient.post(`${this.apiUrl}/inbox`, reward);
        return result.data;
    }

    public async getPlayerInboxItems(playerId: string): Promise<any> {
        const result = await this.httpClient.get(`${this.apiUrl}/inbox/player/${playerId}`);
        return result.data;
    }

    public async sendInboxToPlayer(playerId: string, inboxItem: InboxItem): Promise<any> {
        const result = await this.httpClient.put(`${this.apiUrl}/inbox/player/${playerId}`, inboxItem);
        return result.data;
    }

    public async sendInboxToPlayers(playerIds: string[], reward: any): Promise<any> {
        throw new Error('Method not implemented.');
    }

}
