import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import {useState} from 'react';
import {MaintenanceStatus} from '../../service/maintenance/maintenanceService';

export interface MaintenanceDialogProps {
    error?: string;
    open: boolean;
    currentMessage: string;
    currentStatus: MaintenanceStatus;
    handleClose: () => void;
    handleSubmit: (message: string, status:MaintenanceStatus) => Promise<void>;
}

export const MaintenanceDialog = (props: MaintenanceDialogProps) => {
    const {open, handleClose, handleSubmit, error, currentMessage, currentStatus} = props;
    const [message, setMessage] = useState<string>(currentMessage);
    const [status, setStatus] = useState<MaintenanceStatus>(currentStatus);

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>Update Maintenance</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <FormControl sx={{m: 1, minWidth: 120}}>
                        <InputLabel htmlFor="visibility">Status</InputLabel>
                        <Select defaultValue={status} id="visibility" onChange={(event) =>{setStatus(event.target.value as MaintenanceStatus);}}>
                            <MenuItem key={MaintenanceStatus.None} value={MaintenanceStatus.None}>None</MenuItem>
                            <MenuItem key={MaintenanceStatus.SoftMaintenance} value={MaintenanceStatus.SoftMaintenance}>SoftMaintenance</MenuItem>
                            <MenuItem key={MaintenanceStatus.HardMaintenance} value={MaintenanceStatus.HardMaintenance}>HardMaintenance</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{m: 1, minWidth: 320}}>
                        <TextField id="message" type={'text'} defaultValue={message} onChange={(event) => {setMessage(event.target.value);}} label='Message' />
                    </FormControl>
                </Box>
                <span style={{color: error !== 'Success' ? '#FF0000' : '#00FF00'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={() => {handleSubmit(message, status);}}>Send</Button>
            </DialogActions>
        </Dialog>
    );
};