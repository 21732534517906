import React, {useState} from 'react';
import {Button, CircularProgress, FormGroup, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from '@mui/material';
import StaticDataSchemaMap, {StaticDataObjectType, StaticDataSchema, StaticDataSharingType} from 'src/temp/staticDataSchemaMap';
import {Link} from 'react-router-dom';
import {IStaticDataContextService} from 'src/service/staticData';
import {useService} from 'src/hook/serviceLocatorHook';
import {Service} from '../service/serviceLocator';

export interface ContextProps {
	schemas: any;
	editingContent: any;
	liveContent: any;
	editingSharedContent: any;
	liveSharedContent: any;
}

export const Context = (props: ContextProps) => {
    const idifyTip = (tip) => {
        return tip.replaceAll(/\W/g, '-');
    };

    const [context, setContext] = useState<any>(props.editingContent || {});
    const [staticDataContextService] = useService<IStaticDataContextService>(Service.StaticDataContextService);
    const [refreshLoading, setRefreshLoading] = useState<boolean>();
    const [loadingTips, setLoadingTips] = useState<Map<string, string>>(() => {
        const tips = new Map<string, string>();

        for (const tip of context.loading_tips || []) {
            tips.set(idifyTip(tip), tip);
        }

        return tips;
    });
    const [newLoadingTip, setNewLoadingTip] = useState<string>('');

    const updateContext = async () => {
        context.loading_tips = [...loadingTips.values()];

        await staticDataContextService?.updateContext(context.id, context);
    };

    const publishContext = async () => {
        context.loading_tips = [...loadingTips.values()];

        await staticDataContextService?.publishContext(context.id, context);
    };

    const handleChangeContext = (event) => {
        const newContext = {
            ...context,
            [event.target.name]: event.target.value
        };
        setContext(newContext);
    };

    const handleSubmitContext = (event) => {
        updateContext();
        event.preventDefault();
    };

    const handlePublishContext = (event) => {
        publishContext();
        event.preventDefault();
    };

    const handleRefreshAll = async (event) => {
        setRefreshLoading(true);
        await staticDataContextService?.refreshAllDefinitions(context.id);
        window.location.reload();
    };

    const renderDefinition = (schema: StaticDataSchema, contextId: string, definitionId: string, editingContent: any, liveContent: any) => {
        const editingManifest = editingContent?.manifests?.[definitionId] ?? {};
        const liveManifest = liveContent?.manifests?.[definitionId] ?? {};

        const versionColor = editingManifest.hash === liveManifest.hash ? '#2AE630' : '#E6BE2A';
        const editingDate = new Date(editingManifest.timestamp);
        const liveDate = new Date(liveManifest.timestamp);

        return (<TableRow key={`schema_${context.id}_${definitionId}`}>
            <TableCell><b>{definitionId}</b></TableCell>
            <TableCell>{schema.description}</TableCell>
            <TableCell>
                <span style={{color: versionColor}}>{editingManifest.hash}</span><br />
				({editingDate.toDateString()} - {editingDate.toLocaleTimeString()})<br />
                <span style={{'fontStyle': 'italic'}}>{editingManifest.author} - {editingManifest.comment}</span><br />
            </TableCell>
            <TableCell>
                <span style={{color: versionColor}}>{liveManifest.hash}</span><br />
				({liveDate.toDateString()} - {liveDate.toLocaleTimeString()})<br />
                <span style={{'fontStyle': 'italic'}}>{liveManifest.publisher} - {liveManifest.publisher_comment}</span>
            </TableCell>
            <TableCell><Button variant={'contained'}><Link to={`/admin/staticData/${contextId}/${definitionId}`} style={{'color': '#FFFFFF', 'textDecoration': 'none'}}>Edit</Link></Button></TableCell>
        </TableRow>);
    };

    const renderContent = () => {
        const data: any = [];

        const schema: any = props.schemas.getSchema('ContextDefinition');

        for (const field in schema.properties) {
            if (schema.properties[field].type === 'array') {
                continue;
            }

            data.push(
                <Grid item xs={3} key={`context_${context.id}_${field}`}>
                    <TextField name={field} defaultValue={context[field]} onBlur={handleChangeContext} multiline label={field}></TextField>
                </Grid>
            );
        }

        const tips: any[] = [];
        const existingTips: any[] = [];

        for (const tipKey of loadingTips.keys()) {
            existingTips.push(<div key={`tip_${tipKey}`}><TextField defaultValue={loadingTips.get(tipKey)} onChange={evt => {
                const updatedTips: Map<string, string> = new Map<string, string>(loadingTips);
                updatedTips.set(tipKey, evt.target.value);

                setLoadingTips(updatedTips);
            }} /><Button variant={'contained'} onClick={() => {
                const updatedTips: Map<string, string> = new Map<string, string>(loadingTips);

                updatedTips.delete(tipKey);

                setLoadingTips(updatedTips);
            }}>Delete</Button><br /></div>);
        }

        tips.push(<div key={`loading_tips_${context.id}`}>
            <h4>Loading Tips</h4>
            {existingTips}
            <br/>
            <TextField name={'newTip'} defaultValue={newLoadingTip} onChange={evt => setNewLoadingTip(evt.target.value)} />
            <Button variant={'contained'} onClick={() => {
                if (newLoadingTip) {
                    const updatedTips: Map<string, string> = new Map<string, string>(loadingTips);

                    updatedTips.set(idifyTip(newLoadingTip), newLoadingTip);

                    setNewLoadingTip('');
                    setLoadingTips(updatedTips);
                }
            }}>Add New Loading Tip</Button>
        </div>);

        const sharedManifestIds: Array<string> = Array.from(props.schemas?.getSchemasBySharingType(StaticDataSharingType.Shared)?.keys() || []);
        sharedManifestIds.sort();

        const openIssues: any[] = [];

        const sharedManifests: any[] = [];

        const loading: any = [];

        if (refreshLoading) {
            loading.push(<div key={'transfer_loading'}>
                <CircularProgress /> Loading...
            </div>);
        }

        for (const definitionId of sharedManifestIds) {
            const schema: any = props.schemas.getSchemasBySharingType(StaticDataSharingType.Shared)?.get(definitionId);

            if (schema.objectType !== StaticDataObjectType.Definition) {
                continue;
            }

            const issue = (props.editingSharedContent?.manifests?.[definitionId] ?? {}).last_validation;

            if (issue) {
                openIssues.push(<div key={`open_issue_${definitionId}`} style={{color: '#FF0000'}}><Link to={`/admin/staticData/${StaticDataSchemaMap.SharedContext}/${definitionId}`}>{definitionId}</Link> - {issue}</div>);
            }

            sharedManifests.push(renderDefinition(schema, StaticDataSchemaMap.SharedContext, definitionId, props.editingSharedContent, props.liveSharedContent));
        }

        const contextualManifestIds: Array<string> = Array.from(props.schemas?.getSchemasBySharingType(StaticDataSharingType.Contextual)?.keys() || []);
        contextualManifestIds.sort();

        const contextualManifests: any[] = [];

        for (const definitionId of contextualManifestIds) {
            const schema: any = props.schemas?.getSchemasBySharingType(StaticDataSharingType.Contextual)?.get(definitionId);

            if (schema.objectType !== StaticDataObjectType.Definition) {
                continue;
            }

            const issue = (context?.manifests?.[definitionId] ?? {}).last_validation;

            if (issue && issue != '--') {
                openIssues.push(<div key={`open_issue_${definitionId}`} style={{color: '#FF0000'}}><Link to={`/admin/staticData/${context.id}/${definitionId}`}>{definitionId}</Link> - {issue}</div>);
            }

            contextualManifests.push(renderDefinition(schema, context.id, definitionId, context, props.liveContent));
        }

        if (openIssues.length == 0) {
            openIssues.push(<div key={'no_open_issues'} style={{color: '#00FF00'}}>No Issues</div>);
        }

        return (<div key={`context_${context.id}`}>
            <h3>{context.name}</h3>
            <form onSubmit={handleSubmitContext}>
                <FormGroup>
                    <Grid container spacing={2}>
                        {data}
                    </Grid>
                    <br />
                    {tips}
                    <br />
                    <Button variant="contained" type="submit">Update</Button><br/>
                    <Button variant="contained" onClick={handlePublishContext}>Publish</Button>
                </FormGroup>
            </form>
            <h3>Open Issues</h3>
            {openIssues}
            <br />
            <Button variant="contained" onClick={handleRefreshAll}>Refresh</Button><br/>
            {loading}

            <h4>Legend</h4>
            <span style={{fontStyle: 'italic', color: '#2AE630'}}>Editing Data Matches Published</span><br/>
            <span style={{fontStyle: 'italic', color: '#E6BE2A'}}>Editing Data Out of Sync with Published</span>
            <h3>Manifests (Shared)</h3>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Definition Type</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Description</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Current Version</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Live Version</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{sharedManifests}</TableBody>
                </Table>
            </TableContainer>

            <h3>Manifests (Contextual)</h3>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Definition Type</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Description</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Current Version</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Live Version</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{contextualManifests}</TableBody>
                </Table>
            </TableContainer>
        </div>);
    };

    return (
        <div>
            {renderContent()}
        </div>
    );
};
