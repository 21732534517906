import * as React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {Link, useParams} from 'react-router-dom';
import {useService} from 'src/hook/serviceLocatorHook';
import {Button, capitalize, Typography} from '@mui/material';
import {Service} from '../service/serviceLocator';
import {StaticDataManifests} from '../component/staticData/staticDataManifests';
import {IStaticDataDefinitionService} from '../service/staticData/staticDataDefinitionService';


export const getServerSideProps = async function(ctx) {
    return {
        props: {
            env: ctx.req.env,
            context: ctx.req.query.context,
            definitionId: ctx.req.query.definitionId
        }
    };
};

interface DefinitionsProps {
	context: string;
	definitionId: string;
	env: string;
}

const DefinitionPage = (props: { env: string }) => {
    const params = useParams();
    document.title = `${params.definitionId} - Sapling Admin (${capitalize(props.env)})`;
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [staticDataDefinitionService] = useService<IStaticDataDefinitionService>(Service.StaticDataDefinitionService);
    const [sets, setSets] = useState<any>();

    const staticDataProps: DefinitionsProps = {
        env: props.env,
        definitionId: params.definitionId || '',
        context: params.context || '',
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await staticDataDefinitionService?.getDefinition(params.context || '', params.definitionId || '');

            setSets(result.Response);
            setIsFetching(false);
        };

        fetchData().catch((error) => {
            // issue was there.
        });
    }, [staticDataDefinitionService, params]);

    const renderSets = (props: DefinitionsProps, res) => {
        return (<div key={'sets'}>
            <StaticDataManifests context={staticDataProps.context} definitionId={staticDataProps.definitionId} editingManifest={sets.editingManifest} liveManifest={sets.liveManifest} versions={sets.versions} sets={sets.sets} />
        </div>);
    };

    return (
        <>
            <Container>
                <Box
                    sx={{
                        my: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                </Box>
            </Container>
            {isFetching ?
                (
                    <div>
                        <Typography>Fetching data...</Typography>
                    </div>
                )
                :
                (
                    <>
                        <Container>
                            <h1>Sets: {staticDataProps.context} - {staticDataProps.definitionId}</h1>
                            <Button variant={'contained'}><Link to={'/admin/contexts'} style={{'color': '#FFFFFF', 'textDecoration': 'none'}}>Back</Link></Button>
                        </Container>
                        <br />
                        {renderSets(staticDataProps, sets.Response)}
                    </>
                )
            }

        </>
    );
};

export default DefinitionPage;
