import {Button, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import {useService} from 'src/hook/serviceLocatorHook';
import {Service} from 'src/service/serviceLocator';
import {IPlayerService} from 'src/service/player/playerService';

export interface NarrativeChapter {
    cid: string; // NarrativeChapterId;
    sid: string; // CurrentStepId;
    s: boolean; // IsStarted;
    c: boolean; // Completed;
}

export interface NarrativeChapterProps {
    chapter: NarrativeChapter;
    submitChange(saveTo: NarrativeChapter): void;
}

export const NarrativeChapterItem = (props: NarrativeChapterProps) =>
{
    const {chapter, submitChange} = props;
    const [editMode, setEditMode] = useState<boolean>(false);
    const [narrativeChapter, setNarrativeChapter] = useState<NarrativeChapter>({...chapter});
    const toggleEditMode = () => {setEditMode(!editMode);};

    const handleSubmit = async() => {
        await submitChange(narrativeChapter);
        setEditMode(false);
    };
    return (
        <TableRow key={chapter.cid}>
            {editMode?
                <>
                    <TableCell>{chapter.cid}</TableCell>
                    <TableCell>
                        <TextField id="currentStepId" type={'text'} defaultValue={narrativeChapter.sid} onChange={(e) => setNarrativeChapter({...narrativeChapter, sid: e.target.value})} label='Current Step' />
                    </TableCell>
                    <TableCell>
                        <FormControl sx={{m: 1, minWidth: 120}}>
                            <InputLabel htmlFor="isStarted">Is Started</InputLabel>
                            <Select id="isStarted" defaultValue={narrativeChapter.s} onChange={(e) => setNarrativeChapter({...narrativeChapter, s: e.target.value as boolean})}>
                                <MenuItem key={'Started'} value={true as any}>Started</MenuItem>
                                <MenuItem key={'NotStarted'} value={false as any}>Not Started</MenuItem>
                            </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <FormControl sx={{m: 1, minWidth: 120}}>
                            <InputLabel htmlFor="isCompleted">Is Completed</InputLabel>
                            <Select id="isCompleted" defaultValue={narrativeChapter.c} onChange={(e) => setNarrativeChapter({...narrativeChapter, c: e.target.value as boolean})}>
                                <MenuItem key={'Completed'} value={true as any}>Completed</MenuItem>
                                <MenuItem key={'NotCompleted'} value={false as any}>Not Completed</MenuItem>
                            </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <Button variant="outlined" color="success" startIcon={<CheckIcon />} onClick={handleSubmit}>Save</Button>
                        <Button variant="outlined" color="error" startIcon={<CancelIcon />} onClick={toggleEditMode}>Cancel</Button>
                    </TableCell>
                </>
                :
                <>
                    <TableCell>{chapter.cid}</TableCell>
                    <TableCell>{chapter.sid}</TableCell>
                    <TableCell>{chapter.s ? 'Started' : ''}</TableCell>
                    <TableCell>{chapter.c ? 'Completed' : ''}</TableCell>
                    <TableCell>
                        <Button variant="outlined" startIcon={<EditIcon />} onClick={toggleEditMode}>Edit</Button>
                    </TableCell>
                </>
            }
        </TableRow>


    );
};


export interface NarrativeChapterEditorProps {
    chapters: NarrativeChapter[];
    context: string;
    playerId: string;
}

export const NarrativeChapterEditor = (props: NarrativeChapterEditorProps) => {
    const {context, playerId} = props;
    const [playerService] = useService<IPlayerService>(Service.PlayerService);
    const [chapters, setChapters] = useState<NarrativeChapter[]>(props.chapters);
    const updateNarrativeChapters = (updatedChapter: NarrativeChapter) => {
        const updated = chapters.map(c => {
            if(c.cid === updatedChapter.cid)
            {
                return updatedChapter;
            }
            return c;
        });
        setChapters(updated);
    };
    const headers = ['Chapter', 'Step', 'Started', 'Completed', 'Edit'].map(text => {
        return (<TableCell key={`NarrativeChapter_${text}`} sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>{text}</TableCell>);
    });

    return (
        <>
            <Typography variant='h3'>{}</Typography>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headers}
                        </TableRow>
                    </TableHead>
                    <TableBody>{chapters.map(c => <NarrativeChapterItem chapter={c} submitChange={(async chapter => {
                        const result = await playerService?.updateNarrativeChapter(playerId, context, chapter);
                        if(result.Response)
                        {
                            updateNarrativeChapters(chapter);
                        }
                    })}/>)}</TableBody>
                </Table>
            </TableContainer>
        </>);
};