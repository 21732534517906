// KK - This class shouldn't need to exist, since we already have it in ../common.
// TODO: Figure out how to reference common classes from React

export enum StaticDataMappingType {
	IdMapped,
	IdTabbed,
	Config,
	Itemized
}

export enum StaticDataSharingType {
	Global,
	Contextual,
	Shared
}

export enum StaticDataObjectType {
	Definition,
	Enum,
	Item
}

export interface StaticDataSchema {
	description: string;
	objectType: StaticDataObjectType;
	mappingType: StaticDataMappingType;
	sharingType: StaticDataSharingType;
	properties: any;
}

export default class StaticDataSchemaMap {
    private readonly _schemas: Map<string, StaticDataSchema>;
    private readonly _schemasByType: Map<StaticDataSharingType, Map<string, StaticDataSchema>>;

    public static SharedContext = 'Shared';

    // KK - This is gross, we should rely on something else. Maybe we have to concede that we can't use Flatbuffers as-is :'(
    public static ItemizedSchemaParameterName = 'items';

    constructor(schemas: Map<string, StaticDataSchema>) {
        this._schemas = schemas;
        this._schemasByType = new Map<StaticDataSharingType, Map<string, StaticDataSchema>>();

        this._schemas.forEach((schema, id) => {
            // KK - Coalesce itemized definitions
            if (schema.objectType == StaticDataObjectType.Definition) {
                if (schema.properties[StaticDataSchemaMap.ItemizedSchemaParameterName] && schema.properties[StaticDataSchemaMap.ItemizedSchemaParameterName][StaticDataSchemaMap.ItemizedSchemaParameterName]) {
                    const split = schema.properties[StaticDataSchemaMap.ItemizedSchemaParameterName][StaticDataSchemaMap.ItemizedSchemaParameterName].$ref.split('/');
                    const itemSchemaProperty = split[split.length - 1];
                    schema.properties[StaticDataSchemaMap.ItemizedSchemaParameterName] = this._schemas.get(itemSchemaProperty)?.properties;

                    // KK - This isn't necessary but I'm hateful
                    delete this._schemas[itemSchemaProperty];
                }
            }

            if (!this._schemasByType.has(schema.sharingType)) {
                this._schemasByType.set(schema.sharingType, new Map<string, StaticDataSchema>());
            }

            this._schemasByType.get(schema.sharingType)?.set(id, schema);
        });
    }

    public getSchema(definitionId: string): StaticDataSchema | undefined {
        return this._schemas.get(definitionId);
    }

    public getSchemasBySharingType(schemaType: StaticDataSharingType): Map<string, StaticDataSchema> | undefined {
        return this._schemasByType.get(schemaType);
    }

    // KK - I hate these, shouldn't we be able to serialize/deserialize maps?

    public static deserialize(serialized: any): StaticDataSchemaMap {
        const deserialized = new Map<string, StaticDataSchema>();

        for (const key in serialized) {
            deserialized.set(key, serialized[key]);
        }

        return new StaticDataSchemaMap(deserialized);
    }

    public static serialize(existing: StaticDataSchemaMap): any {
        const serialized = {};

        existing._schemas.forEach((schema, id) => {
            serialized[id] = schema;
        });

        return serialized;
    }
}
