import {IHttpClient, IServerResponse} from 'src/core/http';

// consider to have own service or merge service as StaticDataService.
export interface IStaticDataContextService {
	getContexts(): Promise<any>;
	updateContext(id: string, data: any): Promise<any>;
	publishContext(id: string, data: any): Promise<any>;
	refreshAllDefinitions(id: string): Promise<any>;
    getStaticDataSchema(): Promise<any>;
}

export class StaticDataContextService implements IStaticDataContextService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }
    public async updateContext(id: string, data: any): Promise<any> {
        const result = await this.httpClient.put(`${this.apiUrl}/staticData/contexts/${id}`, data);
        return result.data;
    }

    public async publishContext(id: string, data: any): Promise<any> {
        const result = await this.httpClient.put(`${this.apiUrl}/staticData/contexts/${id}/publish`, data);
        return result.data;
    }

    public async refreshAllDefinitions(id: string): Promise<any> {
        const result = await this.httpClient.get(`${this.apiUrl}/staticData/contexts/${id}/refreshAllDefinitions`);
        return result.data;
    }

    public async getContexts(): Promise<any> {
        const result = await this.httpClient.get<IServerResponse<any>>(`${this.apiUrl}/staticData/contexts`);
        // handling error etc..
        return result.data;
    }

    public async getStaticDataSchema(): Promise<any> {
        const result = await this.httpClient.get<IServerResponse<any>>(`${this.apiUrl}/staticData/contexts/schema`);
        return result.data;
    }

}
