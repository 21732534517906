import * as React from 'react';
import {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import StaticDataSchemaMap, {StaticDataMappingType, StaticDataSchema} from 'src/temp/staticDataSchemaMap';
import {Link, useParams} from 'react-router-dom';
import {useService} from 'src/hook/serviceLocatorHook';
import {IStaticDataSetService} from 'src/service/staticData';
import {Accordion, AccordionDetails, AccordionSummary, Button, capitalize, Card, CardContent, Checkbox, Grid, Stack, TextField, Typography} from '@mui/material';
import {keyColumn, textColumn} from 'react-datasheet-grid';
import {Service} from '../service/serviceLocator';
import {textFieldColumn} from '../component/staticData/sheetEditors/customInputFieldColumn';
import {IdTabbedStaticDataSheetEditor} from '../component/staticData/sheetEditors/idTabbedStaticDataSheetEditor';
import {IdMappedStaticDataSheetEditor} from '../component/staticData/sheetEditors/idMappedStaticDataSheetEditor';
import {confirmAlert} from 'react-confirm-alert';

export const getServerSideProps = async function(ctx) {
    return {
        props: {
            env: ctx.req.env,
            context: ctx.req.query.context,
            definitionId: ctx.req.query.definitionId
        }
    };
};

interface SetProps {
    context: string;
    definitionId: string;
    env: string;
    setId: string;
}

const SetPage = (props: { env: string }) => {
    const params = useParams();
    document.title = `${params.definitionId} (${params.setId}) - Sapling Admin (${capitalize(props.env)})`;
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [staticDataSetService] = useService<IStaticDataSetService>(Service.StaticDataSetService);
    const [data, setData] = useState<any>();
    const [priority, setPriority] = useState<number>(1);
    const [sheetId, setSheetId] = useState<string>('');
    const [sheetTabName, setSheetTabName] = useState<string>('');
    const [clearExisting, setClearExisting] = useState<boolean>(true);
    const [exportCompleted, setExportCompleted] = useState<boolean>();

    useEffect(() => {
        const fetchData = async () => {
            setIsFetching(true);
            const result = await staticDataSetService?.getSet(params.context || '', params.definitionId || '', params.setId || '');

            setData(result);
            setIsFetching(false);
        };

        fetchData().catch((error) => {
            // issue was there.
        });
    }, [staticDataSetService, params]);

    const staticDataProps: SetProps = {
        env: props.env,
        definitionId: params.definitionId || '',
        context: params.context || '',
        setId: params.setId || ''
    };

    const showConfirmImportSheet = () => {
        confirmAlert({
            title: 'Google Sheet Import',
            message: `Are you sure you want to replace editing data with content from ${sheetId}:${sheetTabName}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await staticDataSetService?.importFromSheet(staticDataProps.context, staticDataProps.definitionId, staticDataProps.setId, sheetId, sheetTabName);
                        window.location.reload();
                    }
                },
                {
                    label: 'No',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {}
                }
            ]
        });
    };

    const showConfirmExportToSheet = () => {
        confirmAlert({
            title: 'Google Sheet Export',
            message: `Are you sure you want to overwrite your tab ${sheetTabName} in sheet ${sheetId} with current editing data?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setExportCompleted(false);

                        await staticDataSetService?.exportSetIntoSheet(staticDataProps.context, staticDataProps.definitionId, staticDataProps.setId, sheetId, sheetTabName, clearExisting);

                        setExportCompleted(true);
                    }
                },
                {
                    label: 'No',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {}
                }
            ]
        });
    };

    const renderSet = (res) => {
        const lastEditorDate = new Date(res.set.lastChange?.timestamp);

        return (<div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">Last Change Info</Typography>
                    <Grid item xs={12}>
                        Id: {res.set.id}
                    </Grid>
                    <Grid item xs={12}>
                        Author: {res.set.lastChange?.author}
                    </Grid>
                    <Grid item xs={12}>
                        Comment: {res.set.lastChange?.comment}
                    </Grid>
                    <Grid item xs={12}>
                        Date: {`${lastEditorDate.toDateString()} - ${lastEditorDate.toLocaleTimeString()}`}
                    </Grid>
                    <Grid item xs={12}>
                        <span style={{color: '#FF0000'}}>{res.set.lastChange?.validationResult}</span>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Set Priority</Typography>
                    <input type={'text'} defaultValue={res.set.priority} onChange={(evt) => setPriority(parseInt(evt.target.value))}/>
                    &nbsp;
                    <Button onClick={() => {
                        staticDataSetService?.updateSetPriority(staticDataProps.context, staticDataProps.definitionId, staticDataProps.setId, priority, {
                            comment: `Updated priority to ${priority}.`
                        });
                    }} variant={'contained'}>Update Priority</Button>
                </Grid>
            </Grid>
        </div>);
    };

    const renderImportExport = (res) => {
        const map: StaticDataSchemaMap = StaticDataSchemaMap.deserialize(res.schema);
        const schema: StaticDataSchema | undefined = map.getSchema(staticDataProps.definitionId);

        if (!schema) {
            return;
        }

        const sheetOptions: any[] = [];

        sheetOptions.push(<div key={'importExport'}>Sheet Id: <TextField rows={1} defaultValue={sheetId} onChange={evt => setSheetId(evt.target.value)}/></div>);

        if (schema.mappingType != StaticDataMappingType.IdTabbed) {
            sheetOptions.push(<div>Tab Name: <TextField rows={1} defaultValue={sheetTabName} onChange={evt => setSheetTabName(evt.target.value)}/></div>);
        }

        sheetOptions.push(<div key={'clearExisting'}>Clear Existing Data: <Checkbox checked={clearExisting} onChange={evt => setClearExisting(evt.target.checked)} /></div>);

        return <div>
            <Accordion defaultExpanded={false}>
                <AccordionSummary><Typography variant="h5">Import/Export</Typography></AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12}>
                        <Typography variant="h5">Google Sheet Import/Export</Typography>
                        <Grid item xs={12}>
                            {sheetOptions}
                            <span style={{fontStyle: 'italic'}}>Note: Ensure your sheet has been shared with {data.Response.sheetUser}</span>
                        </Grid>
                        <br />
                        <Grid item xs={12}>
                            <Button onClick={showConfirmImportSheet} variant="contained">Import From Google Sheet</Button>
                            &nbsp;
                            <Button onClick={showConfirmExportToSheet} variant="contained">Export To Google Sheet</Button>

                            {exportCompleted ? 'Export completed.' : ''}
                        </Grid>
                    </Grid>
                    <br /><br/>
                    <Grid item xs={12}>
                        <Typography variant="h5">Admin Import/Export</Typography>
                        Import Set <input type="file" multiple={false} accept=".json,application/json" onChange={importSet}/>
                        <br/>
                        <Button onClick={() => {
                            staticDataSetService?.exportSet(staticDataProps.context, staticDataProps.definitionId, staticDataProps.setId, `${staticDataProps.context}-${staticDataProps.definitionId}-${staticDataProps.setId}-${res.set.lastChange?.timestamp}.json`);
                        }} variant={'contained'}>Export</Button>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>;
    };

    const renderGrid = (res) => {
        const map: StaticDataSchemaMap = StaticDataSchemaMap.deserialize(res.schema);
        const schema: StaticDataSchema | undefined = map.getSchema(staticDataProps.definitionId);

        if (!schema) {
            return;
        }

        const data = res.data || [];
        let dataSchema = schema.properties;
        const columns: Array<any> = [];
        const descriptionRow: any = {
            isHeader: true
        };

        switch (schema.mappingType) {
        case StaticDataMappingType.IdMapped:
            for (const key in dataSchema) {
                descriptionRow[key] = dataSchema[key].description;

                columns.push({
                    ...keyColumn(key, textFieldColumn),
                    title: key,
                    minWidth: 250,
                    disabled: ({rowData}) => rowData?.isHeader
                });
            }

            return (<div key="sheet">
                <IdMappedStaticDataSheetEditor
                    context={staticDataProps.context}
                    definitionId={staticDataProps.definitionId}
                    setId={staticDataProps.setId}
                    schema={res.schema}
                    conditions={res.set.conditions}
                    data={[
                        descriptionRow,
                        ...data
                    ]}
                    columns={columns}/>
            </div>);
            break;
        case StaticDataMappingType.Config:
            columns.push({
                ...keyColumn('id', textColumn),
                title: 'Id',
                disabled: true
            });

            columns.push({
                ...keyColumn('value', textColumn),
                title: 'Value'
            });

            // eslint-disable-next-line no-case-declarations
            const values: any = [];

            for (const propertyName in res.schema[staticDataProps.definitionId].properties) {
                values.push({id: propertyName, value: data[propertyName]});
            }

            return (<div key="sheet">
                <IdMappedStaticDataSheetEditor
                    context={staticDataProps.context}
                    definitionId={staticDataProps.definitionId}
                    setId={staticDataProps.setId}
                    schema={res.schema}
                    conditions={res.set.conditions}
                    data={values}
                    columns={columns}/>
            </div>);
            break;
        case StaticDataMappingType.Itemized:
            dataSchema = schema.properties[StaticDataSchemaMap.ItemizedSchemaParameterName];

            for (const key in dataSchema) {
                columns.push({
                    ...keyColumn(key, textFieldColumn),
                    title: key,
                    minWidth: 250,
                    disabled: ({rowData}) => rowData?.isHeader
                });
            }

            return (<div key="sheet">
                <IdMappedStaticDataSheetEditor
                    context={staticDataProps.context}
                    definitionId={staticDataProps.definitionId}
                    setId={staticDataProps.setId}
                    schema={res.schema}
                    conditions={res.set.conditions}
                    data={data}
                    columns={columns}/>
            </div>);
            break;
        case StaticDataMappingType.IdTabbed:
            dataSchema = schema.properties[StaticDataSchemaMap.ItemizedSchemaParameterName];

            for (const key in dataSchema) {
                descriptionRow[key] = dataSchema[key].description;

                columns.push({
                    ...keyColumn(key, textFieldColumn),
                    title: key,
                    minWidth: 250,
                    disabled: ({rowData}) => rowData?.isHeader
                });
            }

            // eslint-disable-next-line no-case-declarations
            const ids: string[] = [];
            // eslint-disable-next-line no-case-declarations
            const grids = {};

            for (const rowId in data) {
                ids.push(rowId);

                grids[rowId] = [
                    descriptionRow,
                    ...data[rowId]
                ];
            }

            return (<div key="sheet">
                <IdTabbedStaticDataSheetEditor
                    context={staticDataProps.context}
                    definitionId={staticDataProps.definitionId}
                    setId={staticDataProps.setId}
                    schema={res.schema}
                    conditions={res.set.conditions}
                    data={grids}
                    activeId={ids[0]}
                    existingIds={ids}
                    descriptionRow={descriptionRow}
                    columns={columns}/>
            </div>);
            break;
        }
    };

    const importSet = (event) => {
        const reader = new FileReader();

        let fileLoaded = (e) => {
            handleImportSet(e.target.result);
        };

        fileLoaded = fileLoaded.bind(this);
        reader.onload = fileLoaded;
        reader.readAsText(event.target.files[0]); // read the file
    };

    const handleImportSet = async (data: any) => {
        await staticDataSetService?.importSet(staticDataProps.context, staticDataProps.definitionId, staticDataProps.setId, data);
        window.location.reload();
    };

    return (
        <>
            <Container>
                <Box
                    sx={{
                        my: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                </Box>
            </Container>
            {isFetching ?
                (
                    <div>
                        <Typography>Fetching data...</Typography>
                    </div>
                )
                :
                (
                    <>
                        <Container>
                            <h1>Definitions: {staticDataProps.context} - {staticDataProps.definitionId} - {data.Response.set.name}</h1>
                            <Button variant={'contained'}><Link to={`/admin/staticData/${staticDataProps.context}/${staticDataProps.definitionId}`} style={{'color': '#FFFFFF', 'textDecoration': 'none'}}>Back</Link></Button>
                        </Container>
                        <br/>
                        <Container maxWidth="xl">
                            <Stack spacing={2}>
                                <Card variant="outlined">
                                    <CardContent>
                                        {renderSet(data.Response)}
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Container>
                        <br />
                        <Container maxWidth="xl">
                            <Stack spacing={2}>
                                <Card variant="outlined">
                                    <CardContent>
                                        {renderImportExport(data.Response)}
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Container>
                        <br/><br/>
                        {renderGrid(data.Response)}
                    </>
                )
            }

        </>
    );
};

export default SetPage;
