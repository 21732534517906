import {styled} from '@mui/material';
import {useState} from 'react';
import {AppBar} from './appBar/appBar';
import {ILink, NavigationDrawer} from './navigation/navigationDrawer';

export interface ILayoutProps {
    env: string;
    version: string;
    children?:
    | React.ReactChild
    | React.ReactChild[];
}

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

export const Layout = (props: ILayoutProps) => {
    const links: ILink[] = [
        {title: 'Static Data', link: '/admin/contexts'},
        {title: 'Players', link: '/admin/players'},
        {title: 'Save States', link: '/admin/saveStates'},
        {title: 'Static Data Transfer', link: '/admin/staticDataTransfer'},
        {title: 'Maintenance', link: '/admin/maintenance'}
    ];
    const [open, setOpen] = useState<boolean>(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (
        <div key={'Layout'}>
            <AppBar open={open} env={props.env} version={props.version} handleDrawerOpen={handleDrawerOpen} />
            <NavigationDrawer setOpen={setOpen} isOpened={open} links={links} />
            <Main open={open}>
                {props.children}
            </Main>
        </div>
    );
};
