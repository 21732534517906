import {IHttpClient} from 'src/core/http';
import {SaveState} from '../../component/player/saveState/saveStateDialog';
import {ServiceResponse} from '../../temp/commonSchema';

export interface ISaveStateService {
	getSaveStates(): Promise<any>;
    getSaveState(id: string): Promise<any>;
    createSaveState(content: SaveState): Promise<any>;
    createSaveStateFromPlayerId(playerId: string, name: string, description: string): Promise<any>;
    replacePlayerWithSaveState(playerId: string, saveStateId: string): Promise<any>;
    replaceAllPlayersWithSaveState(saveStateId: string): Promise<ServiceResponse>;
    updateSaveState(id: string, content: SaveState): Promise<boolean>;
    deleteSaveState(id: string): Promise<boolean>;
    importSaveStates(data: any): Promise<any>;
    exportSaveStates(ids: Array<string>, fileName: string): Promise<any>;
}

export class SaveStateService implements ISaveStateService {
    private apiUrl: string;
    private httpClient: IHttpClient;

    constructor(apiUrl: string, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    public async getSaveStates(): Promise<any> {
        const result = await this.httpClient.get(`${this.apiUrl}/saveState`);
        return result.data;
    }

    public async getSaveState(id: string): Promise<any> {
        const result = await this.httpClient.get(`${this.apiUrl}/saveState/${id}`);
        return result.data;
    }

    public async createSaveState(content: SaveState): Promise<any> {
        const result = await this.httpClient.post(`${this.apiUrl}/saveState`, content);
        return result.data;
    }

    public async createSaveStateFromPlayerId(playerId: string, name: string, description: string): Promise<any> {
        const result = await this.httpClient.post(`${this.apiUrl}/saveState/player/${playerId}`, {name, description});
        return result.data;
    }

    public async replacePlayerWithSaveState(playerId: string, saveStateId: string): Promise<any> {
        const result = await this.httpClient.put(`${this.apiUrl}/saveState/player/${playerId}/saveState/${saveStateId}`, {});
        return result.data;
    }

    public async replaceAllPlayersWithSaveState(saveStateId: string): Promise<any> {
        const result = await this.httpClient.put(`${this.apiUrl}/saveState/allPlayers/saveState/${saveStateId}`, {});
        return result.data;
    }

    public async updateSaveState(id: string, content: any): Promise<any> {
        const result = await this.httpClient.put(`${this.apiUrl}/saveState/${id}`, content);
        return result.data;
    }

    public async deleteSaveState(id: string): Promise<boolean> {
        const result = await this.httpClient.delete(`${this.apiUrl}/saveState/${id}`);

        return true;
    }

    public async importSaveStates(data: any): Promise<any> {
        const result = await this.httpClient.post(`${this.apiUrl}/saveState/import`, {
            data,
        });
        return result.data;
    }

    public async exportSaveStates(ids: Array<string>, fileName: string): Promise<any> {
        await this.httpClient.download(`${this.apiUrl}/saveState/export/${ids.join(',')}`, fileName);
    }
}
