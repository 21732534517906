import React from 'react';
import './App.css';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {RouterProvider} from 'react-router-dom';
import {devTheme, playtestTheme, localTheme, prodTheme, stageTheme, internalTheme} from './layout/theme';
import {AppRouter, PrivateRoute} from './core/router';
import Index from './page';
import ContextPage from './page/contexts';
import DefinitionPage from './page/definition';
import SetPage from './page/set';
import PlayerPage from './page/players';
import PlayerProfilePage from './page/playerProfile';
import StaticDataTransferPage from './page/staticDataTransfer';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {Service, ServiceContext, ServiceLocator} from './service/serviceLocator';
import {AuthService} from './service/auth/authService';
import {HttpClient, IHttpClient,} from './core/http';
import {StaticDataTransferService} from './service/staticData/staticDataTransferService';
import {StaticDataContextService} from './service/staticData/staticDataContextService';
import {StaticDataDefinitionService} from './service/staticData/staticDataDefinitionService';
import {StaticDataSetService} from './service/staticData/staticDataSetService';
import {PlayerService} from './service/player/playerService';
import {MaintenanceService} from './service/maintenance/maintenanceService';
import MaintenancePage from './page/maintenance';
import {InboxService} from './service/inbox/inboxService';
import SaveStatesPage from './page/saveStates';
import {SaveStateService} from './service/player/saveStateService';
import {AuthorizedHttpClient} from './core/http/authorizedHttpClient';

function App() {
    const appRouter = new AppRouter();

    const unauthorizedHttpClient: IHttpClient = new HttpClient();
    const authService = new AuthService(process.env.REACT_APP_API_URL || '', window.localStorage, unauthorizedHttpClient);
    const authorizedHttpClient: IHttpClient = new AuthorizedHttpClient(unauthorizedHttpClient, authService);
    const playerService = new PlayerService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const staticDataTransferService = new StaticDataTransferService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const staticDataContextService = new StaticDataContextService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const staticDataDefinitionService = new StaticDataDefinitionService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const staticDataSetService = new StaticDataSetService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const maintenanceService = new MaintenanceService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const inboxService = new InboxService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);
    const saveStateService = new SaveStateService(process.env.REACT_APP_API_URL || '', authorizedHttpClient);

    authService.initialize();
    const env = process.env.REACT_APP_ENV || 'local';
    const version = process.env.REACT_APP_VERSION;

    const router = appRouter.registerRoutes([
        PrivateRoute('/', <Index env={env} />),
        PrivateRoute('/admin', <Index env={env} />),
        PrivateRoute('/admin/contexts', <ContextPage env={env} />),
        PrivateRoute('/admin/maintenance', <MaintenancePage env={env}/>),
        PrivateRoute('/admin/players', <PlayerPage env={env} />),
        PrivateRoute('/admin/players/:id', <PlayerProfilePage env={env} />),
        PrivateRoute('/admin/staticData/:context/:definitionId', <DefinitionPage env={env} />),
        PrivateRoute('/admin/staticData/:context/:definitionId/:setId', <SetPage env={env} />),
        PrivateRoute('/admin/staticDataTransfer', <StaticDataTransferPage env={env} />),
        PrivateRoute('/admin/saveStates', <SaveStatesPage env={env} />),
    ]);

    const serviceLocator = new ServiceLocator();

    // to make it testable, url should be injected.
    serviceLocator.register(Service.HttpClient, authorizedHttpClient);
    serviceLocator.register(Service.AuthService, authService);
    serviceLocator.register(Service.StaticDataTransferService, staticDataTransferService);
    serviceLocator.register(Service.StaticDataContextService, staticDataContextService);
    serviceLocator.register(Service.StaticDataDefinitionService, staticDataDefinitionService);
    serviceLocator.register(Service.StaticDataSetService, staticDataSetService);
    serviceLocator.register(Service.PlayerService, playerService);
    serviceLocator.register(Service.MaintenanceService, maintenanceService);
    serviceLocator.register(Service.InboxService, inboxService);
    serviceLocator.register(Service.SaveStateService, saveStateService);

    const theme = getTheme(env);

    return (
        <ServiceContext.Provider value={serviceLocator}>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
                    <RouterProvider router={router} />
                </GoogleOAuthProvider>
            </ThemeProvider>
        </ServiceContext.Provider>
    );
}
const getTheme = (env: string) => {
    switch (env) {

    case 'dev':
        return devTheme;
    case 'stage':
        return stageTheme;
    case 'prod':
        return prodTheme;
    case 'internal':
        return internalTheme;
    case 'playtest':
        return playtestTheme;
    default:
        return localTheme;

    }
};
export default App;
