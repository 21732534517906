import {LoaderFunction, RouteObject} from 'react-router-dom';
import React, {useContext, useEffect, useState} from 'react';
import {Layout} from 'src/layout/layout';
import {GoogleLogin, useGoogleLogin} from '@react-oauth/google';
import {Service, ServiceContext} from 'src/service/serviceLocator';
import {AuthService} from 'src/service/auth/authService';
import {Box, Button, Container} from '@mui/material';
import {useAuth} from 'src/hook/authHook';

export const PublicRoute = (
    path: string,
    component: React.ReactNode,
    children?: RouteObject[]
): RouteObject => {
    return {
        path,
        element: <PublicComponent>{component}</PublicComponent>,
        children,
    };
};

// TODO: Create the Unauthenticated page that redirects to login page.
export const PrivateRoute = (
    path: string,
    component: React.ReactNode,
    children?: RouteObject[]
): RouteObject => {
    // use auth hook
    return {
        path,
        element: <PrivateComponent>{component}</PrivateComponent>,
        children,
    };
};

const PrivateComponent = (props: any) => {
    const login = useGoogleLogin({
        onSuccess: codeResponse => handleCodeSuccess(codeResponse),
        flow: 'auth-code'
    });

    const provider = useContext(ServiceContext);
    const authService = provider.get<AuthService>(Service.AuthService);
    const [isAuthenticated] = useAuth();

    const handleCodeSuccess = (response) => {
        console.log(response);
        authService?.signInWithCode(response.code);
    };

    return (
        <Layout env={process.env.REACT_APP_ENV || 'local'} version={process.env.REACT_APP_VERSION || ''}>
            {isAuthenticated
                ?
                props.children
                :
                <Container maxWidth="xl">
                    <Box
                        sx={{
                            my: 6,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button onClick={login}>Sign In with Google</Button>
                    </Box>
                </Container>
            }

        </Layout>);
};

const PublicComponent = (props: any) => {
    return (<Layout env={process.env.NODE_ENV} version={process.env.REACT_APP_VERSION || ''}>{props.children}</Layout>);
};
