import React, {useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent, Checkbox,
    Container,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import {DataSheetGrid} from 'react-datasheet-grid';
import 'react-datasheet-grid/dist/style.css';
import {useService} from '../../../hook/serviceLocatorHook';
import {IStaticDataSetService} from '../../../service/staticData';
import {Service} from '../../../service/serviceLocator';
import {StaticDataSetConditions} from '../staticDataSetConditions';
import {TabbedStaticDataSheetEditorProps} from '../props/tabbedStaticDataSheetEditorProps';

export const IdTabbedStaticDataSheetEditor = (props: TabbedStaticDataSheetEditorProps) => {
    const [staticDataSetService] = useService<IStaticDataSetService>(Service.StaticDataSetService);
    const [grids, setGrids] = useState<any>(props.data);
    const [activeId, setActiveId] = useState<string>(props.activeId);
    const [newId, setNewId] = useState<string>('');
    const [existingIds, setExistingIds] = useState<Array<string>>(props.existingIds);
    const [comment, setComment] = useState<string>();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [searchCaseSensitive, setSearchCaseSensitive] = useState<boolean>(true);
    const [searchResults, setSearchResults] = useState<Array<string>>([]);

    const onCellsChanged = (changed: any) => {
        const newGrids = {...grids};
        newGrids[activeId] = changed;
        setGrids(newGrids);
    };

    const updateSet = async (event) => {
        event.preventDefault();

        await staticDataSetService?.updateSet(props.context, props.definitionId, props.setId, {data: grids, comment});

        window.location.reload();
    };

    const deleteActiveId = (event) => {
        const newGrids = {...grids};
        delete newGrids[activeId];

        const newIds = new Array<string>();

        for (const id of existingIds) {
            if (id != activeId) {
                newIds.push(id);
            }
        }

        setGrids(newGrids);
        setExistingIds(newIds);
        setActiveId(newIds[0]);

        event.preventDefault();
    };

    const handleNewItem = (event) => {
        const newGrids = {...grids};
        newGrids[newId] = [props.descriptionRow];

        const newIds = [...existingIds];
        newIds.push(newId);

        setGrids(newGrids);
        setExistingIds(newIds);
        setActiveId(newId);

        event.preventDefault();
    };

    const handleSearch = (event) => {
        const results: Array<any> = [];
        const allResults: Array<any> = [];

        for (const gridId in grids) {
            const grid = grids[gridId];
            const gridResults: Array<any> = [];

            // Start at 1 because 0 is the header row
            for (let i = 1; i < grid.length; i++) {
                const row = grid[i];

                for (const col of props.columns) {
                    const colName = col.id;

                    if ((searchCaseSensitive && row[colName]?.includes(searchTerm)) || !searchCaseSensitive && row[colName]?.toLowerCase().includes(searchTerm?.toLowerCase())) {
                        const match = row[colName].match(new RegExp(searchTerm || '', 'ig'));

                        gridResults.push(<TableRow>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{colName}</TableCell>
                            <TableCell><span dangerouslySetInnerHTML={{__html: row[colName].replaceAll(match, `<b>${match}</b>`)}} /></TableCell>
                        </TableRow>);
                    }
                }
            }

            if (gridResults.length > 0) {
                allResults.push(<TableRow>
                    <TableCell colSpan={3} style={{backgroundColor: '#ADD8E6', fontWeight: 'bold'}}>{gridId}</TableCell>
                </TableRow>);
                allResults.push(...gridResults);
            }
        }

        if (allResults.length == 0) {
            allResults.push(<TableRow>
                <TableCell colSpan={3}>Nothing found.</TableCell>
            </TableRow>);
        }

        results.push(<div key={'searchResults'}>
            <h4>Search Results</h4>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Row</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Column</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Result</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{allResults}</TableBody>
                </Table>
            </TableContainer>
        </div>);

        setSearchResults(results);
        event.preventDefault();
    };

    const renderSheetData = (grids) => {
        const sheetData: JSX.Element[] = [];

        sheetData.push(<div key="new">
            <form onSubmit={handleNewItem}>
                <label>ID: <input type="text" name="newId" defaultValue={newId} onChange={evt => setNewId(evt.target.value)} />  </label>
                <Button type="submit" variant={'contained'}>Create new</Button>
            </form><br/>
        </div>);

        const ids: JSX.Element[] = [];

        for (const id of existingIds) {
            ids.push(<option key={id} value={id}>{id}</option>);
        }

        sheetData.push(<div key="ids">
            <select value={activeId} onChange={evt => setActiveId(evt.target.value)}>
                {ids}
            </select>&nbsp;
            <Button type={'button'} onClick={deleteActiveId} variant={'contained'}>Delete</Button>
        </div>);

        if (existingIds.length > 0) {
            sheetData.push(
                <div key="data_sheet">
                    <DataSheetGrid
                        value={grids[activeId]}
                        height={grids[activeId].length * 60}
                        onChange={onCellsChanged}
                        columns={props.columns}
                    />
                </div>
            );
        }

        return sheetData;
    };

    return (
        <div>
            <Container maxWidth="lg">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary><Typography variant="h5">Conditions</Typography></AccordionSummary>
                                <AccordionDetails>
                                    <StaticDataSetConditions
                                        context={props.context}
                                        definitionId={props.definitionId}
                                        setId={props.setId}
                                        conditions={props.conditions}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>

            <h3>Save</h3>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant="h6">Comment: </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField rows={4} defaultValue={comment} onChange={evt => setComment(evt.target.value)} multiline />
                </Grid>
            </Grid>

            <Button onClick={updateSet} variant="contained">Save</Button>

            <h3>Search</h3>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <input type="text" name="searchTerm" defaultValue={searchTerm} onChange={evt => setSearchTerm(evt.target.value)} />
                    &nbsp;&nbsp;<Button type="submit" variant={'contained'} onClick={handleSearch}>Search</Button>
                    <br />
                    <Checkbox checked={searchCaseSensitive} onChange={evt => setSearchCaseSensitive(evt.target.checked)} /> Case Sensitive?
                </Grid>
                <Grid item xs={12}>
                    {searchResults}
                </Grid>
            </Grid>


            <h3>Data</h3>
            {renderSheetData(grids)}
        </div>
    );
};
