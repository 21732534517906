import React, {useState} from 'react';
import {Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography} from '@mui/material';
import 'react-datasheet-grid/dist/style.css';
import {StaticDataSetConditionsProps} from './props/staticDataSetConditionsProps';
import {IStaticDataSetService} from '../../service/staticData';
import {Service} from '../../service/serviceLocator';
import {useService} from '../../hook/serviceLocatorHook';

export const StaticDataSetConditions = (props: StaticDataSetConditionsProps) => {
    const [staticDataSetService] = useService<IStaticDataSetService>(Service.StaticDataSetService);
    const [conditions, setConditions] = useState<Array<any>>(props.conditions || []);
    const [conditionsComment, setConditionsComment] = useState<string>();
    const [newConditionType, setNewConditionType] = useState<string>('PlayerLevel');
    const [newConditionOperator, setNewConditionOperator] = useState<string>('Equal');
    const [newConditionKey, setNewConditionKey] = useState<string>();
    const [newConditionValue, setNewConditionValue] = useState<string>();

    const updateSetConditions = async (event) => {
        event.preventDefault();

        await staticDataSetService?.updateSetConditions(props.context, props.definitionId, props.setId, {
            comment: conditionsComment,
            conditions
        });

        window.location.reload();
    };

    const renderConditions = () => {
        const renderConditions: any[] = [];

        const conditionTypes = [
            <option key={'PlayerLevel'} value={'PlayerLevel'}>Player Level</option>,
            <option key={'Segment'} value={'Segment'}>Segment</option>,
            <option key={'ClientVersion'} value={'ClientVersion'}>Client Version</option>,
            <option key={'CreatedAt'} value={'CreatedAt'}>Created At</option>,
            <option key={'Date'} value={'Date'}>Date (Timestamp in UTC)</option>,
            <option key={'Seed'} value={'Seed'}>Seed</option>,
            <option key={'Language'} value={'Language'}>Language</option>,
            <option key={'Country'} value={'Country'}>Country</option>
        ];

        const conditionOperators = [
            <option key={'Equal'} value={'Equal'}>Equals</option>,
            <option key={'NotEqual'} value={'NotEqual'}>Doesn't Equal</option>,
            <option key={'In'} value={'In'}>In</option>,
            <option key={'NotIn'} value={'NotIn'}>Not In</option>,
            <option key={'GreaterThan'} value={'GreaterThan'}>Greater Than</option>,
            <option key={'GreaterThanOrEqual'} value={'GreaterThanOrEqual'}>Greater Than or Equal</option>,
            <option key={'LessThan'} value={'LessThan'}>Less Than</option>,
            <option key={'Seed'} value={'LessThanOrEqual'}>Less Than or Equal</option>
        ];

        renderConditions.push(<div key={'new_condition'}>
            <select defaultValue={'PlayerLevel'} onChange={evt => setNewConditionType(evt.target.value)}>
                {conditionTypes}
            </select>&nbsp;
            <select defaultValue={'Equal'} onChange={evt => setNewConditionOperator(evt.target.value)}>
                {conditionOperators}
            </select>&nbsp;
            <label>Value: <input type="text" name="newConditionKey" defaultValue={newConditionValue} onChange={evt => setNewConditionValue(evt.target.value)} /></label>
            <label>Key (Optional): <input type="text" name="newConditionKey" defaultValue={newConditionKey} onChange={evt => setNewConditionKey(evt.target.value)} /></label>
			&nbsp;&nbsp;
            <Button type={'button'} onClick={createNewCondition} variant={'contained'}>Create New Condition</Button>
        </div>);

        const conditionRows: any[] = [];
        for (let i = 0; i < conditions.length; i++) {
            const condition = conditions[i];

            conditionRows.push(<TableRow key={`condition_${i}`}>
                <TableCell>{condition.type}</TableCell>
                <TableCell>{condition.operator}</TableCell>
                <TableCell>{condition.key || '--'}</TableCell>
                <TableCell>{condition.value}</TableCell>
                <TableCell>
                    <Button variant="contained" onClick={() => {
                        removeCondition(i);
                    }}>Delete</Button>
                </TableCell>
            </TableRow>);
        }

        renderConditions.push(<TableContainer key={'conditions'} component={Paper}>
            <Table sx={{minWidth: 700}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Type</TableCell>
                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Operator</TableCell>
                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Key</TableCell>
                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Value</TableCell>
                        <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{conditionRows}</TableBody>
            </Table>
        </TableContainer>);

        return renderConditions;
    };

    const createNewCondition = () => {
        const updatedConditions = [...conditions];

        updatedConditions.push({
            type: newConditionType,
            operator: newConditionOperator,
            key: newConditionKey,
            value: newConditionValue
        });

        setConditions(updatedConditions);
    };

    const removeCondition = (conditionIndex) => {
        const updatedConditions: any[] = [];

        for (let i = 0; i < conditions.length; i++) {
            if (i == conditionIndex) {
                continue;
            }

            updatedConditions.push(conditions[i]);
        }

        setConditions(updatedConditions);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant="h6">Comment: </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField rows={1} fullWidth={true} defaultValue={conditionsComment} onChange={evt => setConditionsComment(evt.target.value)} />
                </Grid>
            </Grid>

            <Button onClick={updateSetConditions} variant="contained">Save Conditions</Button>

            {renderConditions()}
        </div>
    );
};
