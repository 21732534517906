import {BehaviorSubject} from 'rxjs';
import {IHttpClient, AUTHORIZATION_HEADER, IServerResponse} from 'src/core/http';

export interface IAuthService extends IAuthStreams {
    getAuthPayload(): Promise<any>;
    initialize(): void;
    signOut(): void;
    isAuthenticated(): boolean;
    isExpired(): boolean;
    refresh(): void;
}

// I am not sure where to store the streams.. it will be used in the hook to avoid unnecessary api call.
export interface IAuthStreams {
    isSignedInStream: BehaviorSubject<boolean>;
}

export class AuthService implements IAuthService {
    private static readonly AUTH_PAYLOAD: string = 'authPayload';
    private static readonly AUTH_REFRESH_TOKEN: string = 'authRefreshToken';
    private static readonly AUTH_EXPIRE_DATE: string = 'authExpireDate';
    private static readonly AUTH_ID_TOKEN: string = 'authIdToken';
    private apiUrl: string;
    private storage: Storage;
    private httpClient: IHttpClient;

    public isSignedInStream: BehaviorSubject<boolean>;

    constructor(apiUrl: string, storage: Storage, httpClient: IHttpClient) {
        this.apiUrl = apiUrl;
        this.storage = storage;
        this.httpClient = httpClient;
        this.isSignedInStream = new BehaviorSubject(false);
    }

    public async initialize(): Promise<void> {
        if(this.isExpired())
        {
            await this.refresh();
            return;
        }

        const idToken = this.storage.getItem(AuthService.AUTH_ID_TOKEN);
        if(idToken)
        {
            this.httpClient.addHeader(AUTHORIZATION_HEADER, idToken);
            await this.getAuthPayload();
            this.isSignedInStream.next(true);
        }
    }

    public async signInWithCode(code: string): Promise<boolean> {
        const result = await this.httpClient.post<IServerResponse<{
            refresh_token: string,
            id_token: string,
            expiry_date: number,
        }>>(`${this.apiUrl}/auth/getAccessToken`, {code});
        if (result.hasError === false) {
            if (result.data.Response) {
                this.isSignedInStream.next(true);
                this.storage.setItem(AuthService.AUTH_REFRESH_TOKEN, result.data.Response.refresh_token);
                this.updateIdToken(result.data.Response.id_token, result.data.Response.expiry_date);
            }
            return true;
        }
        return false;
    }

    public async getAuthPayload(): Promise<any> {
        const payload = this.storage.getItem(AuthService.AUTH_PAYLOAD);
        if (!payload) {
            const result = await this.httpClient.get<IServerResponse<any>>(`${this.apiUrl}/auth/me`);
            if (result.hasError === false) {
                this.storage.setItem(AuthService.AUTH_PAYLOAD, JSON.stringify(result.data));
                return result.data;
            }
        }
        return JSON.parse(payload || '');
    }

    public signOut(): void {
        this.isSignedInStream.next(false);
        this.httpClient.removeHeader(AUTHORIZATION_HEADER);

        this.storage.removeItem(AuthService.AUTH_PAYLOAD);
        this.storage.removeItem(AuthService.AUTH_REFRESH_TOKEN);
        this.storage.removeItem(AuthService.AUTH_ID_TOKEN);
        this.storage.removeItem(AuthService.AUTH_EXPIRE_DATE);
    }

    public async refresh(): Promise<void> {
        const refreshToken = this.storage.getItem(AuthService.AUTH_REFRESH_TOKEN);
        if(refreshToken)
        {
            const result = await this.httpClient.post<IServerResponse<{
                id_token: string,
                expires_in: number
            }>>(`${this.apiUrl}/auth/refreshToken`,{
                refreshToken
            });

            if (result.hasError) {
                return;
            }
            const expiresAt = Date.now() + (result.data.Response.expires_in * 1000);
            this.updateIdToken(result.data.Response.id_token, expiresAt);
            this.isSignedInStream.next(true);
        }
    }

    public isExpired(): boolean
    {
        const expiresAt = this.storage.getItem(AuthService.AUTH_EXPIRE_DATE);
        if(expiresAt)
        {
            return Number(expiresAt) - Date.now() < 0;
        }
        return true;
    }

    public isAuthenticated(): boolean {
        return this.isSignedInStream.getValue();
    }

    private updateIdToken(idToken: string, expiresAt: number)
    {
        this.httpClient.addHeader(AUTHORIZATION_HEADER, idToken);
        this.storage.setItem(AuthService.AUTH_ID_TOKEN, idToken);
        this.storage.setItem(AuthService.AUTH_EXPIRE_DATE, expiresAt.toString());
    }
}